import { useMemo } from 'react';
import { Space, TableColumnProps } from 'antd';
import styled from 'styled-components';
import { useTableSearch } from '../../shared/components/TableSearch';
import { groupDeploymentsByComponent } from '../helper/latestComponents';
import { SoftwareItemVersionPreview } from './CurrentDeployment/SoftwareDetails/SoftwareItemVersionPreview';
import { Comparator } from '../../../domain/extensions/comparison';
import Table from '../../../contexts/shared/components/Table/Table';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import ComponentInstallations from './ComponentInstallations';
import { DeploymentPlantTableVersionRowItem } from './DeploymentPlanTableVersionRowItem';
import { InstalledStatus } from './InstalledStatus';

import type { DeploymentPlan, Device, Project } from '../../../api';
import type { ComponentForDevices } from '../helper/deploymentTypes';
import { SoftwareComponentOpenGateway } from './SoftwareComponentOpenGateway';

const TableText = styled.div`
  word-wrap: break-word;
  word-break: break-word;
`;

export const DeploymentPlanFlatTable = (props: { project: Project; envId: string; plan: DeploymentPlan; devices: Device[]; shouldShowPartial: boolean }) => {
  const softwareComponentSearch = useTableSearch({ searchValueProvider: 'component.name', searchParamId: 'cp_component' });

  const [tablePage, setTablePage] = useSearchParameter('cp_p', '1');
  const tablePageNumber = parseInt(tablePage || '1');

  const [pageSize, setPageSize] = useSearchParameter('cp_ps', '50');
  const pageSizeNumber = parseInt(pageSize || '50');

  const appSorter = (a: ComponentForDevices, b: ComponentForDevices) => Comparator.lexicographicalComparison(a.component.name, b.component.name);

  const installedSorter = (a: ComponentForDevices, b: ComponentForDevices) => {
    if (a.allInstalled && !b.allInstalled) return -1;
    if (b.allInstalled && !a.allInstalled) return 1;
    return 0;
  };

  const byComponent = useMemo(() => groupDeploymentsByComponent(props.plan.deployments), [props.plan.deployments]);

  const filteredFlatDeployments = props.shouldShowPartial ? byComponent.filter((d) => d.targets.some((t) => !t.isInstalled)) : byComponent;

  const columns: TableColumnProps<ComponentForDevices>[] = [
    {
      title: 'Software',
      key: 'name',
      sorter: appSorter,
      ...softwareComponentSearch,
      render: (f: ComponentForDevices) => {
        return <TableText>{f.component.name}</TableText>;
      }
    },
    {
      title: 'Version',
      key: 'version',
      align: 'left',
      render: (f: ComponentForDevices) => {
        return <DeploymentPlantTableVersionRowItem project={props.project} component={f.component} />;
      }
    },
    {
      title: 'Status',
      key: 'state',
      align: 'left',
      sorter: installedSorter,
      render: (f: ComponentForDevices) => {
        return <InstalledStatus component={f} />;
      }
    },
    {
      title: 'Installations',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: 220,
      render: (f: ComponentForDevices) => {
        return <ComponentInstallations component={f} plan={props.plan} projectId={props.project.idProject} envId={props.envId} />;
      }
    },
    {
      title: 'Actions',
      key: 'operation',
      fixed: 'right',
      align: 'center',
      width: 180,
      render: (f: ComponentForDevices) => (
        <Space>
          <SoftwareComponentOpenGateway mode="download" component={f.component} project={props.project} />
          <SoftwareComponentOpenGateway mode="toolmanager" component={f.component} project={props.project} />
          <SoftwareItemVersionPreview component={f.component} projectId={props.project.idProject.toString()} />
        </Space>
      )
    }
  ];

  return (
    <Table
      size="small"
      rowKey={(i) => i.identifier}
      columns={columns}
      scroll={{ x: 840 }}
      dataSource={filteredFlatDeployments}
      sticky={{
        offsetHeader: 0
      }}
      pagination={{
        showSizeChanger: true,
        current: tablePageNumber,
        onChange: (n) => setTablePage(n.toString()),
        defaultPageSize: 50,
        pageSize: pageSizeNumber,
        onShowSizeChange: (_, n) => setPageSize(n.toString()),
        pageSizeOptions: ['20', '50', '100', '200', '500']
      }}
    />
  );
};
