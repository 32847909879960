import { useQuery } from '@tanstack/react-query';
import { SoftwareAppUsage } from '../../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../../../session/hooks/usePermissions';

export function useCommonSoftwareAppUsage(softwareAppId: string) {
  const { backend } = useEngineeringBackend();
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  const permissions = usePermissions({ softwareAppId });
  return useQuery<SoftwareAppUsage[], [string, string]>(
    ['commonSoftwareAppUsage', softwareAppId],
    (): Promise<SoftwareAppUsage[]> => {
      return backend.queryCommonAppUsage(softwareAppId);
    },
    {
      enabled: parsedSoftwareAppId > -1 && permissions.engineeringSvc$getCommonSoftwareAppUsage
    }
  );
}
