import { SoftwareComponent } from '../../../../../api';
import { SoftwareItemVersionPreviewContent } from './SoftwareItemVersionPreviewContent';
import { useProjectSoftwareApps } from '../../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useProjectSoftwareApps';
import { useProjectSoftwareAppVersions } from '../../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersions/components/SoftwareItemVersionsList/hooks/useProjectSoftwareAppVersions';

export const ProjectSoftwareAppVersionContentProvider = (props: { projectId: string; component: SoftwareComponent }) => {
  const apps = useProjectSoftwareApps(props.projectId);
  const appVersions = useProjectSoftwareAppVersions(props.projectId, props.component.id.toString());
  const app = apps.data?.find((a) => a.idSoftwareApp === props.component.id);
  const appVersion = appVersions.data?.find((a) => a.idSoftwareAppVersion === props.component.versionId);
  const isLoading = apps.isLoading || appVersions.isLoading;

  return <SoftwareItemVersionPreviewContent isLoading={isLoading} softwareItem={app} version={appVersion} />;
};
