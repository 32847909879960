import { SoftwareComponent } from '../../../../../api';
import { SoftwareItemVersionPreviewContent } from './SoftwareItemVersionPreviewContent';
import { useCommonSoftwareApps } from '../../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useCommonSoftwareApps';
import { useCommonSoftwareAppVersions } from '../../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersions/components/SoftwareItemVersionsList/hooks/useCommonSoftwareAppVersions';

export const CommonSoftwareAppVersionContentProvider = (props: { component: SoftwareComponent }) => {
  const apps = useCommonSoftwareApps();
  const appVersions = useCommonSoftwareAppVersions(props.component.id.toString());

  const app = apps.data?.find((a) => a.idSoftwareApp === props.component.id);
  const appVersion = appVersions.data?.find((a) => a.idSoftwareAppVersion === props.component.versionId);
  const isLoading = apps.isLoading || appVersions.isLoading;
  return <SoftwareItemVersionPreviewContent isLoading={isLoading} softwareItem={app} version={appVersion} />;
};
