import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BundleReleaseLite, BundleReleaseVersionPut } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '@pacts-plugin-api';

export function useUpdateConfigurationVersionNumber() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<BundleReleaseLite, any, [string, string, string, string, BundleReleaseVersionPut]>(
    ([projectId, bundleId, configurationId, versionId, versionNumber]) => {
      return new Promise<BundleReleaseLite>((resolve, reject) => {
        backend
          .updateConfigurationVersionNumber(projectId, bundleId, configurationId, versionId, versionNumber)
          .then((brl) => {
            const versionConfigs = queryClient.getQueryData<BundleReleaseLite[]>(['bundleConfigurationVersions', projectId, bundleId, configurationId]);

            if (versionConfigs) {
              const versionConfigIndex = versionConfigs.findIndex((config) => config.idBundleRelease === brl.idBundleRelease);

              queryClient.setQueryData(
                ['bundleConfigurationVersions', projectId, bundleId, configurationId],
                [...versionConfigs.slice(0, versionConfigIndex), brl, ...versionConfigs.slice(versionConfigIndex + 1)]
              );
            }
            queryClient.invalidateQueries(['bundleConfigurationVersions', projectId, bundleId, configurationId]);
            queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId, configurationId]);
            queryClient.invalidateQueries(['bundleConfiguration', projectId, bundleId, configurationId]);
            presentSuccess(`Successfully updated configuration version ${brl.version}'s number`);
            return resolve(brl);
          })
          .catch(reject);
      });
    }
  );
}

export default useUpdateConfigurationVersionNumber;
