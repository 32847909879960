import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEngineeringBackend } from '../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../api/shared/successPresenter';

export function useDeleteConfigurationVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [string, string, string, string]>(([projectId, bundleId, configurationId, releaseId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteBundleConfigurationVersion(projectId, bundleId, configurationId, releaseId)
        .then(() => {
          queryClient.resetQueries(['bundleConfigurationVersions', projectId, bundleId, configurationId]);
          queryClient.resetQueries(['bundleConfigVersionData', projectId, bundleId, configurationId, releaseId]);
          queryClient.resetQueries(['bundleConfigVersionData', projectId, bundleId, configurationId]);
          queryClient.resetQueries(['bundleConfigVersionData', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfigurations', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfiguration', projectId, bundleId, configurationId]);
          presentSuccess('Successfully deleted configuration version');
          return resolve();
        })
        .catch(reject);
    });
  });
}

export default useDeleteConfigurationVersion;
