import { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { usePermissions } from '../../../../session/hooks/usePermissions';
import { ExpandableMenuItem } from '../../../../shared/components/ExpandableMenuItem';
import { DescriptionValidator, NameValidator } from '../../../../../domain';
import { DescriptionTextAreaInput } from '../../../../shared/components/DescriptionTextAreaInput';
import useAddConfiguration from './hooks/useAddConfiguration';
import { initialProjectConfiguration } from '../../constants';
import { PacTSPermissions } from '@pacts/permissions-lib';
import useConfiguration from './hooks/useConfiguration';
import useConfigurationVersions from '../../hooks/useConfigurationVersions';
import useEditConfiguration from './hooks/useEditConfiguration';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';
import { BundleConfiguration } from '../../../../../api';

export interface AddEditConfigurationProps {
  projectId: string;
  bundleId: string;
  configId?: string;
}

export const AddEditConfiguration = ({ projectId, configId, bundleId }: AddEditConfigurationProps) => {
  const permissions: PacTSPermissions = usePermissions({ projectId });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const configuration = useConfiguration(projectId, bundleId, configId);
  const configurationVersions = useConfigurationVersions(projectId, bundleId, configId);
  const addProjectConfiguration = useAddConfiguration();
  const editProjectConfiguration = useEditConfiguration();
  const isEditMode = !!configId;
  const hasNeededPermission = permissions[isEditMode ? 'engineeringSvc$updateProjectBundleConfiguration' : 'engineeringSvc$addProjectBundleConfiguration'];

  /**
   * Close modal after add action is successful
   */
  useEffect(() => {
    if (addProjectConfiguration.isSuccess || editProjectConfiguration.isSuccess) {
      setDrawerVisible(false);
    }
  }, [addProjectConfiguration.isSuccess, editProjectConfiguration.isSuccess, form]);

  const handleAddEditProjectConfiguration = async (configurationData: BundleConfiguration) => {
    const newConfiguration = {
      ...configurationData,
      idBundleConfiguration: isEditMode ? parseInt(configId) : 0,
      latestBundleReleaseId: isEditMode ? configurationData.latestBundleReleaseId : -1
    };

    await (!isEditMode ? addProjectConfiguration : editProjectConfiguration).mutateAsync([projectId, bundleId, newConfiguration]);
  };

  const AddEditConfigurationButton = !isEditMode ? (
    <Button loading={addProjectConfiguration.isLoading} onClick={() => setDrawerVisible(true)} type="primary">
      New configuration
    </Button>
  ) : (
    <Tooltip title="Edit configuration">
      <ExpandableMenuItem
        icon={<EditOutlined />}
        loading={editProjectConfiguration.isLoading}
        onClick={() => {
          setDrawerVisible(true);
        }}
      >
        Edit
      </ExpandableMenuItem>
    </Tooltip>
  );

  return (
    <>
      {hasNeededPermission && AddEditConfigurationButton}
      <ShiftedDrawer
        destroyOnClose
        title={`${isEditMode ? 'Edit' : 'New'} configuration`}
        open={drawerVisible}
        loading={(isEditMode ? editProjectConfiguration : addProjectConfiguration).isLoading}
        isFormDrawer
        onCancel={() => {
          setDrawerVisible(false);
        }}
        onOk={() => form.submit()}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name="basic"
          labelAlign="left"
          form={form}
          initialValues={{ ...initialProjectConfiguration, ...configuration.data }}
          onFinish={handleAddEditProjectConfiguration}
        >
          <Form.Item label="Name" name="name" rules={[NameValidator.rule()]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description" rules={[DescriptionValidator.rule()]}>
            <DescriptionTextAreaInput />
          </Form.Item>
          {isEditMode && (
            <Form.Item label="Latest release" name="latestBundleReleaseId">
              <Select className={`latest-bundle-release-selector-${projectId}-${bundleId}-${configId}`}>
                {configurationVersions.data?.map((version) => {
                  return (
                    <Select.Option key={version.idBundleRelease} value={version.idBundleRelease}>
                      {version.version}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      </ShiftedDrawer>
    </>
  );
};
