import { Space } from 'antd';
import { InfoTooltip } from './InfoTooltip';
import { Text } from '../base/Components.styled';

export const TableVersionHelp = () => {
  return (
    <Space>
      <Text strong>Version</Text>
      <InfoTooltip text="You can search for versions and targets by entering the search value right into the dropdown." />
    </Space>
  );
};
