import { Col, Form, Select } from 'antd';
import { useSearchParameter } from '../../../../navigation/hooks/useSearchParameter';
import { StyledRow } from '../../../../shared/base/Components.styled';
import { bundleLabel } from './ConfigurationVersionSelection/ConfigurationVersionSelection';
import { useEffect } from 'react';

type ShowBundleItemsOnlySwitchProps = {
  extension?: React.ReactNode[];
  showAll?: boolean;
};

export const ScopeSelection = (props: ShowBundleItemsOnlySwitchProps) => {
  const [showSearchParam, setShowSearchParam] = useSearchParameter('show');
  const selectStyle = {
    width: '100%',
    flex: 'auto'
  } as any;
  const selected = showSearchParam || (props.showAll ? 'all' : 'bundle');

  // Write the initial selected state into the search params.
  useEffect(() => {
    if (showSearchParam) {
      return;
    }

    setShowSearchParam(props.showAll ? 'all' : 'bundle');
  }, [showSearchParam, props.showAll, setShowSearchParam]);

  return (
    <Form layout="horizontal">
      <Form.Item label={bundleLabel('Show')}>
        <StyledRow gutter={8} width={'250px'} wrap={false}>
          <Col flex="auto">
            <Select
              className="bundle-show-bundle-items-only-selector"
              value={selected}
              onSelect={(val: any) => {
                setShowSearchParam(val);
              }}
              style={selectStyle}
              options={[
                { value: 'bundle', label: `Bundle Software` },
                { value: 'all', label: `All Software` }
              ]}
            />
          </Col>
          {props.extension?.map((ext, index) => <Col key={`switch${index}`}>{ext}</Col>)}
        </StyledRow>
      </Form.Item>
    </Form>
  );
};

export default ScopeSelection;
