import { Typography } from 'antd';
import { useState } from 'react';
import { EngineeringToolSelection } from '../../../../domain/engineeringToolsSelection';
import { usePermissions } from '../../../session/hooks/usePermissions';
import { AddTool } from '../AddTool/AddTool';
import ToolsList from './ToolsList';
import { PageHeader } from '@ant-design/pro-layout';
import BreadcrumbsContainer from '../../../projects/components/BreadcrumbsContainer';
import styled from 'styled-components';
import { FlexCardLayout } from '../../../layout/components/FlexCardLayout';

const StyledPageHeader = styled(PageHeader)`
  background-color: ${({ theme }) => theme.colorBgLayout} !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

export const ToolsView = () => {
  const [selectedTools, setSelectedTools] = useState([] as EngineeringToolSelection[]);
  const [initialSelection] = useState([] as EngineeringToolSelection[]);
  const permissions = usePermissions();

  return (
    <FlexCardLayout
      header={
        <>
          <BreadcrumbsContainer items={[{ title: 'Engineering platform' }]} />
          <StyledPageHeader
            title={<Typography.Title level={4}>Engineering Platform Tools Overview</Typography.Title>}
            extra={[permissions.engineeringSvc$addTool && <AddTool key="addTool" />]}
          />
        </>
      }
    >
      <ToolsList selected={selectedTools} onSelect={setSelectedTools} initiallySelected={initialSelection} hideCheckboxes />
    </FlexCardLayout>
  );
};
