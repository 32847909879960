import { useQuery } from '@tanstack/react-query';
import { SoftwareApp } from '../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../session/hooks/usePermissions';

export function useCommonSoftwareApp(softwareAppId: string) {
  const { backend } = useEngineeringBackend();
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  const permissions = usePermissions({ softwareAppId });
  return useQuery<SoftwareApp, [string, string, string]>(
    ['commonSoftwareApp', softwareAppId],
    (): Promise<SoftwareApp> => {
      return backend.queryCommonApp(softwareAppId);
    },
    {
      enabled: parsedSoftwareAppId > -1 && permissions.engineeringSvc$getCommonSoftwareApp
    }
  );
}
