import { Empty, Row } from 'antd';
import styled from 'styled-components';

interface EmptyIconProps {
  description?: string;
  size?: number;
}

const StyledEmptyIconWrapper = styled(Row)`
  margin: 50px 0;

  svg {
    width: 100%;
  }

  .ant-empty-description {
    margin-top: 30px;
  }
`;

export const EmptyIcon = ({ description, size }: EmptyIconProps) => {
  return (
    <StyledEmptyIconWrapper justify="center">
      <Empty imageStyle={{ height: size || 100 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description={description || ''} />
    </StyledEmptyIconWrapper>
  );
};
