import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Typography } from 'antd';
import styled from 'styled-components';

type LinkHandler = (href: string) => { preventDefault: boolean };

const SmallH1 = styled.h1`
  font-size: ${(args) => args.theme.fontSize}px;
  font-weight: bolder;
`;

const SmallH2 = styled.h2`
  font-size: ${(args) => args.theme.fontSize}px;
  font-weight: bold;
`;

const SmallH3 = styled.h3`
  font-size: ${(args) => args.theme.fontSize}px;
  font-weight: bold;
`;

const SmallH4 = styled.h4`
  font-size: ${(args) => args.theme.fontSize}px;
  font-weight: bold;
`;

const SmallH5 = styled.h5`
  font-size: ${(args) => args.theme.fontSize}px;
  font-weight: bold;
`;

const SmallH6 = styled.h6`
  font-size: ${(args) => args.theme.fontSize}px;
  font-weight: bold;
`;

export const MarkdownRenderer = (props: { children: string; centered?: boolean; maxImageWith?: string; linkHandler?: LinkHandler }) => {
  const tableStyles: React.CSSProperties = {
    margin: 2,
    border: '1px solid grey',
    textAlign: 'left',
    minWidth: 36,
    padding: 8,
    verticalAlign: 'top',
    wordBreak: 'break-word'
  };

  return (
    <div className="markdown-rendered">
      <ReactMarkdown
        className={props.centered ? 'verticalCenteredMarkdown' : ''}
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ node, ...innerProps }) => <img alt={innerProps.alt ?? 'undefined'} style={{ maxWidth: props.maxImageWith || '100%' }} {...innerProps} />,
          th: ({ node, ...innerProps }) => <th style={{ ...tableStyles, fontWeight: 'bold' }}>{innerProps.children}</th>,
          td: ({ node, ...innerProps }) => <td style={{ ...tableStyles }}>{innerProps.children}</td>,
          h1: ({ node, ...innerProps }) => <SmallH1>{innerProps.children}</SmallH1>,
          h2: ({ node, ...innerProps }) => <SmallH2>{innerProps.children}</SmallH2>,
          h3: ({ node, ...innerProps }) => <SmallH3>{innerProps.children}</SmallH3>,
          h4: ({ node, ...innerProps }) => <SmallH4>{innerProps.children}</SmallH4>,
          h5: ({ node, ...innerProps }) => <SmallH5>{innerProps.children}</SmallH5>,
          h6: ({ node, ...innerProps }) => <SmallH6>{innerProps.children}</SmallH6>,
          a: ({ node, ...innerProps }) => (
            <a
              target="_blank"
              rel="noreferrer"
              href={innerProps.href}
              style={{ userSelect: 'all' }}
              onClick={(ev) => {
                const href = ((ev.target as any).href as string) || '';
                if (props.linkHandler && props.linkHandler(href).preventDefault) {
                  ev.preventDefault();
                }
                ev.stopPropagation();
              }}
            >
              {innerProps.children}
            </a>
          ),
          p: ({ node, ...innerProps }) => <Typography.Paragraph>{innerProps.children}</Typography.Paragraph>
        }}
      >
        {props.children}
      </ReactMarkdown>
    </div>
  );
};
