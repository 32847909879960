import { useQuery } from '@tanstack/react-query';
import { Tool } from '../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../session/hooks/usePermissions';

export function useTools() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();

  const permissionsList = permissions.engineeringSvc$getTools;
  const specificPermissions = !permissionsList ? permissions.engineeringSvc$getTool$specific() : [];

  return useQuery<Tool[], [string]>(['engineeringTools'], (): Promise<Tool[]> => {
    if (permissionsList) return backend.queryTools();
    return Promise.all(specificPermissions.map((s) => backend.queryTool(s.toolId)));
  });
}
