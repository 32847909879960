import { useQuery } from '@tanstack/react-query';
import { BundleConfiguration } from '../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../session/hooks/usePermissions';

export function useConfiguration(projectId: string, bundleId: string, configurationId: string | undefined = '0') {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  const parsedBundleConfigId = parseInt(configurationId, 10);
  const permissions = usePermissions({ projectId });

  return useQuery<BundleConfiguration, [string, string, string]>(
    ['bundleConfiguration', projectId, bundleId, configurationId],
    (): Promise<BundleConfiguration> => {
      return backend.queryBundleConfiguration(projectId, bundleId, configurationId);
    },
    {
      enabled: parsedProjectId > 0 && parsedBundleId > 0 && parsedBundleConfigId > 0 && permissions.engineeringSvc$getProjectBundleConfiguration
    }
  );
}

export default useConfiguration;
