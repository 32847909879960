import { usePermissions } from '../../../session/hooks/usePermissions';
import type { Tool, ToolVersion } from '../../../../api/engineering/domain/types';
import { useUnstuckSyncingToolVersion } from '../../hooks/useUnstuckSyncingToolVersion';
import { ConfirmationButton } from '../../../../contexts/shared/components/ConfirmationButton';
import { DoubleRightOutlined } from '@ant-design/icons';

export interface UnstuckSyncingToolVersionProps {
  tool: Tool;
  toolVersion: ToolVersion;
}

export const UnstuckSyncingToolVersion = (props: UnstuckSyncingToolVersionProps) => {
  const { tool, toolVersion } = props;

  const permissions = usePermissions({ toolId: tool.id.toString(), idToolVersion: toolVersion.idToolVersion.toString() });
  const { mutate: unstuck, isLoading } = useUnstuckSyncingToolVersion();

  const disabled = toolVersion.state === 'consistent';

  // admin-only feature
  if (!permissions.all$unrestrictedAdministration) return null;

  // only show for syncing elements
  if (disabled) return null;

  return (
    <ConfirmationButton
      icon={<DoubleRightOutlined />}
      caption="Unstuck Syncing"
      okText="Unstuck"
      buttonType="expandable-menu"
      title="This will reset all the links and cannot be undone."
      danger
      loading={isLoading}
      onOk={() => unstuck([tool, toolVersion])}
    >
      Unstuck Syncing
    </ConfirmationButton>
  );
};
