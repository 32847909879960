import { SoftwareAppVersion, ToolVersion } from '../../../../../../../api/engineering/domain/types';
import { ComparisonTag, ComparisonResult } from '../../../../../../comparison/components/ComparisonTag';

export type SoftwareAppVersionComparisonResultProps = {
  a: SoftwareAppVersion | ToolVersion | undefined;
  b: SoftwareAppVersion | ToolVersion | undefined;
};

export function softwareAppVersionComparisonResultValue(a: SoftwareAppVersion | ToolVersion | undefined, b: SoftwareAppVersion | ToolVersion | undefined) {
  if (a && b) {
    if (
      (a as SoftwareAppVersion).idSoftwareAppVersion
        ? (a as SoftwareAppVersion).idSoftwareAppVersion === (b as SoftwareAppVersion).idSoftwareAppVersion
        : (a as ToolVersion).idToolVersion === (b as ToolVersion).idToolVersion
    ) {
      return ComparisonResult.EQUAL;
    }
    return ComparisonResult.VERSION_DIFFERS;
  }
  return ComparisonResult.SELECTION_DIFFERS;
}

export function SoftwareCompareVersionResult(props: SoftwareAppVersionComparisonResultProps) {
  return <ComparisonTag comparisonResult={softwareAppVersionComparisonResultValue(props.a, props.b)} />;
}
