import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../../../api/engineering/hooks/useEngineeringBackend';
import { Tool, ToolCreate } from '../../../../../api/engineering/domain/types';

export function useCreateTool() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<Tool, any, [ToolCreate]>(([tool]) => {
    return new Promise<Tool>((resolve, reject) => {
      backend
        .createTool(tool)
        .then((updatedTool) => {
          queryClient.invalidateQueries(['engineeringTools']);
          presentSuccess(`Successfully created Engineering Tool ${updatedTool.name}`);
          return resolve(updatedTool);
        })
        .catch(reject);
    });
  });
}
