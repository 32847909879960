import { useQuery } from '@tanstack/react-query';
import { usePermissions } from '../../../../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { ToolVersion } from '../../../../../../api';

export function useToolVersionVulnerabilities(toolId: number, version: ToolVersion) {
  const hasIcsComponentId = !!version.icsPortalComponentId;

  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ toolId: toolId.toString(), idToolVersion: version.idToolVersion.toString() });

  return useQuery(
    ['toolAppVersionVulnerabilities', toolId.toString(), version.idToolVersion.toString()],
    () => {
      return backend.queryToolVersionVulnerabilities(toolId, version.idToolVersion);
    },
    {
      enabled: hasIcsComponentId && permissions.engineeringSvc$getToolVersionVulnerabilities
    }
  );
}
