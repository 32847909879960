import { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useDeploymentEnvironments } from '../hooks/useDeploymentEnvironments';
import { useDeleteDeploymentEnvironment } from '../hooks/useDeleteDeploymentEnvironment';
import EnvironmentCard from './EnvironmentCard';
import { useProject } from '../../../contexts/projects/hooks/useProject';
import { useInAppNavigate, useSearchParameter } from '../../../contexts/navigation/hooks';
import { usePermissions } from '../../../contexts/session';
import { Comparator } from '../../../domain';
import { NoEnvironmentsMessage } from './NoEnvironmentsMessage';
import ProjectContentWrapper from '../../../contexts/projects/components/ProjectContentWrapper';

import type { Environment } from '../../../api';
import { AddEditEnvironmentDrawer, AddEditEnvironmentFormProps } from './AddEditEnvironmentDrawer';

const DeploymentEnvironments: React.FC = () => {
  const params = useParams() as any;
  const [projectType] = useSearchParameter('type');
  const navigate = useInAppNavigate();
  const projectId = parseInt(params.projectId);
  const permissions = usePermissions({ projectId: params.projectId });
  const project = useProject(projectId.toString());
  const depEnvs = useDeploymentEnvironments(projectId);
  const [referrer] = useSearchParameter('referrer');

  const [form] = Form.useForm<Environment>();
  const [formModalProps, setFormModalProps] = useState<AddEditEnvironmentFormProps>({
    open: false,
    type: 'create'
  });
  const [formValues, setFormValues] = useState<Record<string, any> | null>(null);

  const deleteDepEnv = useDeleteDeploymentEnvironment();

  const breadcrumbItems = [{ title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` }, { title: 'Environments' }];

  const isLoading = depEnvs.isLoading || project.isLoading;
  const hasNoEnvironments = !isLoading && (depEnvs.data?.length ?? 0) < 1;

  const handleUpdateEnvironment = (env: Environment) => {
    setFormValues({
      name: env.name,
      description: env.description,
      id: env.id,
      mvccId: env.mvccId
    });

    form.setFieldsValue({
      name: env.name,
      description: env.description
    });

    setFormModalProps({ type: 'update', open: true });
  };

  const handleNavigateToDeployments = (envId: string) => {
    navigate(`/projects/${projectId}/deployments/${envId}`);
  };

  const closeModal = () => {
    setFormModalProps((prevProps) => ({ ...prevProps, open: false }));
    setFormValues(null);
  };

  return (
    <ProjectContentWrapper
      title="Environments"
      breadcrumbItems={breadcrumbItems}
      extra={
        permissions.deploymentSvc$addEnvironment ? (
          <Button onClick={() => setFormModalProps({ type: 'create', open: true })} type="primary">
            New environment
          </Button>
        ) : null
      }
      isLoading={isLoading}
    >
      {hasNoEnvironments ? <NoEnvironmentsMessage hideCreateHelp projectId={projectId} referrer={referrer} /> : null}
      <Row gutter={[16, 16]}>
        {depEnvs.data
          ?.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
          .map((env) => (
            <Col key={env.id}>
              <EnvironmentCard
                canEdit={permissions.deploymentSvc$updateEnvironment}
                canDelete={permissions.deploymentSvc$deleteEnvironment}
                env={env}
                onNavigateToDeployments={handleNavigateToDeployments}
                onUpdateEnvironment={handleUpdateEnvironment}
                projectId={projectId}
                onConfirmDelete={() => {
                  deleteDepEnv.mutate([projectId, env.id, env.mvccId]);
                }}
              />
            </Col>
          ))}
      </Row>
      <AddEditEnvironmentDrawer
        projectId={projectId}
        formValues={formValues}
        form={form}
        formModalProps={formModalProps}
        setFormModalProps={setFormModalProps}
        setFormValues={setFormValues}
        closeModal={closeModal}
      />
    </ProjectContentWrapper>
  );
};

export default DeploymentEnvironments;
