import { Skeleton } from 'antd';
import { SoftwareItemSelection } from '../../../../domain/softwareAppsSelections';
import { SoftwareTableProvider } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/components/SoftwareTable/SoftwareTableProvider';
import { useCommonSoftwareAppsScoped } from '../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useCommonSoftwareApps';

export const CommonSoftwareAppsList = (props: {
  selected: SoftwareItemSelection[];
  initiallySelected: SoftwareItemSelection[];
  showBundleItemsOnly?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareItemSelection[]) => any;
}) => {
  const commonSoftwareApps = useCommonSoftwareAppsScoped();

  const isLoading = commonSoftwareApps.isLoading;
  const isSuccess = commonSoftwareApps.isSuccess;

  return (
    <div>
      {isLoading && <Skeleton active />}
      {isSuccess && <SoftwareTableProvider softwareItems={commonSoftwareApps.data} {...props} hideCheckboxes />}
    </div>
  );
};
