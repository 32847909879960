import { Skeleton, TableColumnsType, Typography } from 'antd';

import { EditCommonSoftwareAppVersion } from '../EditSoftwareItemVersion/EditCommonSoftwareAppVersion';
import { SoftwareAppOpenInToolManagerButton } from '../../../SoftwareItemOpenInToolManagerButton/SoftwareAppOpenInToolManagerButton';
import { SemanticVersion } from '../../../../../../../domain/versioning/semanticVersion';
import Table from '../../../../../../shared/components/Table/Table';
import { ExpandableMenu } from '../../../../../../shared/components/ExpandableMenu';
import { ComponentVersionListEntry } from '../../../../../../shared/components/ComponentVersionListEntry';
import { DownloadContextMenuButton } from '../../../../../../shared/components/Download/components/DownloadContextMenuButton';
import { SoftwareAppVersionTitle } from './SoftwareAppVersionTitle';

import { usePermissions } from '../../../../../../session/hooks/usePermissions';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../../../../../api/engineering/domain/types';
import React, { useCallback, useMemo } from 'react';
import { useCommonSoftwareAppVersions } from './hooks/useCommonSoftwareAppVersions';
import { useDeleteCommonSoftwareAppVersion } from './hooks/useDeleteCommonSoftwareAppVersion';
import { scopeToCommon } from '../../../../types';
import { UnstuckSyncingCommonSoftwareAppVersion } from '../../../../../../SoftwareApps/components/UnstuckSyncingCommonSoftwareAppVersion';
import { formatDateTime } from '../../../../../../shared/components/formatDate';
import { useTableSearch } from '../../../../../../shared/components/TableSearch';
import { ConfirmationButton } from '../../../../../../shared/components/ConfirmationButton';

export const CommonSoftwareAppVersionsList = (props: { softwareApp: SoftwareApp; project?: Project;  bundleVersion?: SoftwareAppVersion }) => {
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    softwareAppId: props.softwareApp.idSoftwareApp.toString()
  });

  const versionSearch = useTableSearch({ searchValueProvider: 'version', searchParamId: 'version' });

  const releaseNotesSearch = useTableSearch({
    searchValueProvider: (v: SoftwareAppVersion) => {
      return [v.version, v.releaseNotes, formatDateTime(new Date(v.createdAt || 0)), v.createdBy, formatDateTime(new Date(v.updatedAt || 0)), v.updatedBy]
        .filter(Boolean)
        .join(' ');
    },
    searchParamId: 'releaseNotes'
  });

  const versions = useCommonSoftwareAppVersions(props.softwareApp.idSoftwareApp?.toString() || '');
  const deleteSoftwareAppVersion = useDeleteCommonSoftwareAppVersion();

  const latestVersion = props.softwareApp?.latestVersion?.idSoftwareAppVersion;
  const versionSorter = useCallback(
    (a: SoftwareAppVersion, b: SoftwareAppVersion) => {
      if (a.idSoftwareAppVersion === latestVersion) return -1;
      if (b.idSoftwareAppVersion === latestVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    },
    [latestVersion]
  );

  const data = useMemo(() => {
    return (versions.data ?? []).sort(versionSorter);
  }, [versions.data, versionSorter]);

  const columns: TableColumnsType<SoftwareAppVersion> = [
    {
      title: 'Version',
      key: 'version',
      width: 160,
      ...versionSearch,
      render: (record: SoftwareAppVersion) => {
        return <SoftwareAppVersionTitle app={props.softwareApp} version={record} bundleVersion={props.bundleVersion} />;
      }
    },
    {
      title: 'Release Notes',
      key: 'releaseNotes',
      showSorterTooltip: { title: 'Sort by creation date' },
      sorter: {
        compare: (a: SoftwareAppVersion, b: SoftwareAppVersion): number => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        }
      },
      ...releaseNotesSearch,
      render: (record: SoftwareAppVersion) => <ComponentVersionListEntry record={record} showAudit={permissions.webui$showComponentDetails} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: SoftwareAppVersion) => {
        return (
          <>
            <ExpandableMenu textType id={`common-app-version-actions-${props.softwareApp.idSoftwareApp}-${record.idSoftwareAppVersion}`}>
              <DownloadContextMenuButton artifact={props.softwareApp} version={record} />
              <SoftwareAppOpenInToolManagerButton selection={{ softwareItem: scopeToCommon(props.softwareApp), version: record }} project={props.project} />
              <EditCommonSoftwareAppVersion parentApp={props.softwareApp} softwareAppVersion={record} />
              <UnstuckSyncingCommonSoftwareAppVersion app={props.softwareApp} version={record} />
              {data.length > 1 && permissions.engineeringSvc$deleteCommonSoftwareAppVersion && (
                <ConfirmationButton
                  caption="Delete"
                  title="Deleting version"
                  description="This action cannot be undone."
                  paragraphDescription={
                    <Typography.Paragraph>
                      Are you sure you want to delete version <Typography.Text strong>{record.version}</Typography.Text>?
                    </Typography.Paragraph>
                  }
                  danger
                  disabled={record.idSoftwareAppVersion === props.softwareApp.latestVersion?.idSoftwareAppVersion}
                  onOk={() => {
                    deleteSoftwareAppVersion.mutate([props.softwareApp.idSoftwareApp?.toString() || '', record.idSoftwareAppVersion?.toString() || '']);
                  }}
                />
              )}
            </ExpandableMenu>
          </>
        );
      },
      width: 100
    }
  ];

  return (
    <Skeleton loading={versions.isLoading}>
      <Table
        tableLayout="fixed"
        columns={columns}
        rowKey={(record: SoftwareAppVersion) => record.idSoftwareAppVersion?.toString() || ''}
        dataSource={data}
        sticky={{
          offsetHeader: -25 // Sticky position the header below the drawer header.
        }}
      />
    </Skeleton>
  );
};
