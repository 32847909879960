import { Alert } from 'antd';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  position: absolute;
  top: 78px;
  width: 100%;
  z-index: 5;
`;

const DeploymentPlanFormAlert: React.FC<{ message: string }> = ({ message }) => {
  return <StyledAlert message={message} type="error" showIcon />;
};

export default DeploymentPlanFormAlert;
