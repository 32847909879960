import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../../../../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';

export function useDeleteCommonSoftwareAppVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [string, string]>(([softwareAppId, versionId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteCommonSoftwareAppVersion(softwareAppId, versionId)
        .then(() => {
          queryClient.invalidateQueries(['commonSoftwareAppVersions', softwareAppId]);
          presentSuccess('Successfully deleted Software App Version');
          return resolve();
        })
        .catch(reject);
    });
  });
}
