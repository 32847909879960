import { Result, Space, Typography } from 'antd';

import { ShiftedDrawer } from './ShiftedDrawer';
import { VulnerabilityNotificationCard } from './VulnerabilityNotificationCard';
import { EmptyIcon } from './EmptyIcon';

import type {
  SoftwareAppVersion,
  SoftwareAppVersionVulnerability,
  ToolVersionVulnerability,
  VulnerabilityNotification
} from '../../../api/engineering/domain/types';
import styled from 'styled-components';
import { PageLoading } from './PageLoading';
import { ComponentProps } from 'react';
import { EngineeringBackendError } from '../../../api';

type VulnerabilityNotificationFlattened = VulnerabilityNotification & { vLink?: string; targetId?: number };

const ResultWrapper = styled.div`
  margin-top: 100px;
`;

const DrawerTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

export function VulnerabilityNotificationsDrawer(props: {
  open: boolean;
  title: string;
  notificationsExist: boolean;
  vulnerabilities?: SoftwareAppVersionVulnerability[] | ToolVersionVulnerability[];
  onClose: () => void;
  isError: boolean;
  error: unknown;
  isLoading: boolean;
  appVersion?: SoftwareAppVersion;
  getDrawerContainer?: ComponentProps<typeof ShiftedDrawer>['getContainer'];
}) {
  function getTarget(targetId: number | undefined) {
    if (!props.appVersion || !targetId) return null;

    const idx = props.appVersion.targets.findIndex((t) => t.target.idTarget === targetId);

    if (idx === -1) return null;

    return props.appVersion.targets[idx].target.name;
  }

  const allNotifications = props.vulnerabilities
    ?.flatMap((v) => {
      let targetId = 'targetId' in v ? v.targetId : undefined;

      return v.notifications?.map((n) => ({ vLink: v.link, targetId, ...n }));
    })
    .toSorted((a, b) => {
      // Sort notifications in descending order of the vulnerability score.
      // Notifications that do not have a score should come at the end.
      if ((!a?.cvss && !b?.cvss) || (!a?.cvss?.overallScore && !b?.cvss?.overallScore)) {
        // If both "a" and "b" do not have a score, leave the order as it is.
        return 0;
      } else if (!a?.cvss || !a?.cvss.overallScore) {
        // If "a" does not have a score, "b" should come first.
        return 1;
      } else if (!b?.cvss || !b?.cvss.overallScore) {
        // If "b" does not have a score, "a" should come first.
        return -1;
      }

      return b.cvss.overallScore - a.cvss.overallScore;
    });

  const cards = allNotifications?.map((n) => (
    <VulnerabilityNotificationCard notification={n as VulnerabilityNotificationFlattened} link={n?.vLink} target={getTarget(n?.targetId)} />
  ));

  let content;

  const error = props.error as EngineeringBackendError;

  if (props.isError) {
    if (error.statusCode === 404) {
      content = (
        <ResultWrapper>
          <Result status="warning" subTitle="The entered ICS Portal Component ID does not exist in the ICS Portal." />
        </ResultWrapper>
      );
    } else {
      content = (
        <ResultWrapper>
          <Result status="warning" subTitle="Try later for vulnerability updates. We cannot establish a connection to the ICS Portal." />
        </ResultWrapper>
      );
    }
  } else if (props.isLoading) {
    content = (
      <ResultWrapper>
        <PageLoading />
      </ResultWrapper>
    );
  } else if (!props.notificationsExist) {
    content = (
      <ResultWrapper>
        <EmptyIcon description="No vulnerabilities found!" />
      </ResultWrapper>
    );
  } else if (props.vulnerabilities && props.vulnerabilities.length > 0) {
    content = (
      <Space direction="vertical" style={{ display: 'flex' }}>
        {cards}
      </Space>
    );
  }

  const title = (
    <DrawerTitle level={5} ellipsis={{ tooltip: props.title }}>
      {props.title}
    </DrawerTitle>
  );

  return (
    <ShiftedDrawer getContainer={props.getDrawerContainer} open={props.open} title={title} onClose={props.onClose} styles={{ wrapper: { width: 650 } }}>
      {content}
    </ShiftedDrawer>
  );
}
