import { useQuery } from '@tanstack/react-query';
import { Target } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useSoftwareAppTargets() {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions();
  return useQuery<Target[], [string]>(
    ['softwareAppTargets'],
    (): Promise<Target[]> => {
      return backend.queryAppTargets();
    },
    { enabled: permissions.engineeringSvc$getSoftwareAppTargets }
  );
}
