import { Button, Tooltip } from 'antd';
import { UndoOutlined } from '@ant-design/icons';

type RevertButtonProps = {
  disabled?: boolean;
  onClick: () => any;
};

export const RevertChangesButton = (props: RevertButtonProps) => {
  return (
    <Tooltip title="Revert Modifications">
      <Button className="revert-modifications-button" icon={<UndoOutlined />} onClick={props.onClick} disabled={props.disabled} />
    </Tooltip>
  );
};

export default RevertChangesButton;
