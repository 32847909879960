import { useQuery, useQueryClient } from '@tanstack/react-query';
import { BundleConfiguration, BundleReleaseLite } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';

type BundleConfigurationVersionData = {
  projectId: string;
  bundleId: string;
  bundleConfigName: string;
  bundleConfigId: string;
  bundleConfigReleaseVersion: string;
  bundleConfigReleaseId: string;
  bundleReleases: BundleReleaseLite[];
  bundleConfigurations: BundleConfiguration[];
};
const staleTime = 5 * 60 * 1000;
export const useConfigurationVersionDropdownData = (projectId: string, bundleId: string, bundleConfigId: string, bundleConfigVersionId: string) => {
  const queryClient = useQueryClient();
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  let parsedConfigId = parseInt(bundleConfigId, 10);
  let parsedConfigReleaseId = parseInt(bundleConfigVersionId, 10);
  const permissions = usePermissions({ projectId });

  const key = ['bundleConfigVersionData', projectId, bundleId];
  const enabled =
    parsedProjectId > 0 &&
    parsedBundleId > 0 &&
    permissions.engineeringSvc$getProjectBundleConfigurations &&
    permissions.engineeringSvc$getProjectBundleConfigurationReleases;

  if (parsedConfigId > 0) {
    key.push(bundleConfigId);
    if (parsedConfigReleaseId > 0) key.push(bundleConfigVersionId);
  }

  return useQuery<BundleConfigurationVersionData, [string, string, string, string, string]>(
    key,
    async (): Promise<BundleConfigurationVersionData> => {
      const configsState = queryClient.getQueryState<BundleConfiguration[]>(['bundleConfigurations', projectId, bundleId]);
      let configs = configsState?.data;
      if ((configsState?.dataUpdatedAt || 0) < Date.now() - staleTime) {
        configs = undefined;
      }
      if (!configs) {
        configs = await backend.queryBundleConfigurations(projectId, bundleId);
        queryClient.setQueryData(['bundleConfigurations', projectId, bundleId], configs);
      }
      if (configs.length < 1) {
        return {
          projectId,
          bundleId,
          bundleConfigId: '',
          bundleConfigReleaseId: '',
          bundleConfigName: '',
          bundleConfigReleaseVersion: '',
          bundleConfigurations: [],
          bundleReleases: []
        };
      }

      let existingConfig = configs.find((c) => c.idBundleConfiguration === parsedConfigId);
      if (!existingConfig) {
        parsedConfigId = configs.sort((a, b) => (a.idBundleConfiguration || 0) - (b.idBundleConfiguration || 0))[0].idBundleConfiguration!;
      }
      existingConfig = configs.find((c) => c.idBundleConfiguration === parsedConfigId)!;

      const configVersionsState = queryClient.getQueryState<BundleReleaseLite[]>([
        'bundleConfigurationVersions',
        projectId,
        bundleId,
        parsedConfigId.toString()
      ]);
      let versions = configVersionsState?.data;

      // Tries to prevent possible cache errors
      const versionsArrayEmpty = versions && versions.length < 1;

      const versionsDataStale = (configVersionsState?.dataUpdatedAt || 0) < Date.now() - staleTime;

      // Invalidate versions list
      if (versionsDataStale || versionsArrayEmpty) {
        versions = undefined;
      }
      if (!versions) {
        versions = await backend.queryBundleConfigurationVersions(projectId, bundleId, parsedConfigId.toString());
        queryClient.setQueryData(['bundleConfigurationVersions', projectId, bundleId, parsedConfigId.toString()], versions);
      }

      if (versions.length < 1) {
        const res: BundleConfigurationVersionData = {
          projectId,
          bundleId,
          bundleConfigName: existingConfig.name,
          bundleConfigId: existingConfig.idBundleConfiguration!.toString(),
          bundleConfigReleaseId: '',
          bundleConfigReleaseVersion: '',
          bundleConfigurations: configs,
          bundleReleases: []
        };
        return res;
      }

      let existingRelease = versions.find((c) => c.idBundleRelease === parsedConfigReleaseId);

      const hasLatestReleaesId = existingConfig?.latestBundleReleaseId !== undefined;
      if (!existingRelease && hasLatestReleaesId) {
        existingRelease = versions.find((v) => v.idBundleRelease === existingConfig?.latestBundleReleaseId);
      }
      if (!existingRelease) {
        parsedConfigReleaseId = versions.sort((a, b) => (b.idBundleRelease || 0) - (a.idBundleRelease || 0))[0].idBundleRelease!;
        existingRelease = versions.find((c) => c.idBundleRelease === parsedConfigReleaseId)!;
      }

      const res: BundleConfigurationVersionData = {
        projectId,
        bundleId,
        bundleConfigId: existingConfig.idBundleConfiguration!.toString(),
        bundleConfigName: existingConfig.name,
        bundleConfigReleaseId: existingRelease.idBundleRelease!.toString(),
        bundleConfigReleaseVersion: existingRelease.version!,
        bundleConfigurations: configs,
        bundleReleases: versions
      };
      return res;
    },
    {
      enabled
    }
  );
};
