import { useQuery } from '@tanstack/react-query';
import { ToolCategory } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';

export function useToolCategories() {
  const { backend } = useEngineeringBackend();
  return useQuery<ToolCategory[], [string]>(['engineeringToolCategories'], (): Promise<ToolCategory[]> => {
    return backend.queryToolCategories();
  });
}
