import { Drawer as AntDrawer, Button, Row, Space } from 'antd';
import type { DrawerProps } from 'antd';

type Props = {
  /**
   * If the Drawer is a Form Drawer, two additional buttons
   * for "Cancel" and "Save" will be added to the footer.
   */
  isFormDrawer?: boolean;
  /**
   * This property only applies, if `isFormDrawer` is `true`.
   * A callback that will be executed when the "Save" button is clicked.
   */
  onOk?: () => void;
  /**
   * This property only applies, if `isFormDrawer` is `true`.
   * A callback that will be executed when the "Cancel" button
   * is clicked, as well as when the drawer is closed.
   */
  onCancel?: () => void;
  /**
   * Do not destroy the drawer on close
   */
  doNotDestroyOnClose?: boolean;
};

export const ShiftedDrawer = (props: DrawerProps & Props) => {
  const styles: DrawerProps['styles'] = {
    ...props.styles,
    wrapper: { top: 64 }
  };

  let footer = undefined;

  if (!!props.isFormDrawer) {
    footer = (
      <Row justify="end">
        <Space>
          <Button onClick={props.onCancel}>Cancel</Button>
          <Button type="primary" onClick={props.onOk}>
            Save
          </Button>
        </Space>
      </Row>
    );
  }

  return (
    <AntDrawer
      {...props}
      destroyOnClose={!props.doNotDestroyOnClose}
      onClose={props.isFormDrawer ? props.onCancel : props.onClose}
      width={props.width ?? '830px'}
      styles={styles}
      footer={props.footer ?? footer}
      keyboard={false}
    />
  );
};
