import { useQuery } from '@tanstack/react-query';
import { BundleRelease } from '../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../session/hooks/usePermissions';

export function useConfigurationVersion(projectId: string, bundleId: string, configurationId: string, configurationVersionId: string) {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  const parsedConfigId = parseInt(configurationId, 10);
  const parsedConfigVersionId = parseInt(configurationVersionId, 10);
  const permissions = usePermissions({ projectId });
  const enabled =
    parsedProjectId > 0 &&
    parsedBundleId > 0 &&
    parsedConfigId > 0 &&
    parsedConfigVersionId > 0 &&
    permissions.engineeringSvc$getProjectBundleConfigurationRelease;
  return useQuery<BundleRelease, [string, string, string, string, string]>(
    ['bundleConfigurationVersion', projectId, bundleId, configurationId, configurationVersionId],
    (): Promise<BundleRelease> => {
      return backend.queryBundleConfigurationVersion(projectId, bundleId, configurationId, configurationVersionId, false);
    },
    {
      enabled
    }
  );
}

export default useConfigurationVersion;
