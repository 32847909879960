import { useMemo } from 'react';
import EventEmitter from 'eventemitter3';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Configuration, DeploymentplanApi, DeviceApi, OtcApi, EnvironmentApi } from '@pacts/deploymentservice-api';
import { DeploymentBackend, DeploymentBackendError } from '../service/deploymentBackend';
import {
  DeviceCreate,
  OtcCreate,
  DeploymentPlanLock,
  DeviceDelete,
  OtcDelete,
  DeploymentPlanMeta,
  Device,
  DeploymentPlanCreate,
  DeploymentPlan,
  Otc,
  DeploymentPlanUpdateDeployment,
  DeploymentPlanPatchDeployment
} from '../domain/deployments';
import { useRestBackendConfig } from '../../shared/useBackendConfiguration';
import { SharedAxiosInstance } from '../../shared/sharedAxiosInstance';
import { GlobalState } from '../../../state/globalState';

class RestDeploymentBackend implements DeploymentBackend {
  private readonly emitter: EventEmitter = new EventEmitter();

  private readonly dApi: DeploymentplanApi;

  private readonly eApi: DeviceApi;

  private readonly oApi: OtcApi;

  private readonly envApi: EnvironmentApi;

  constructor(
    public readonly config: Configuration,
    instance: AxiosInstance
  ) {
    this.dApi = new DeploymentplanApi(config, undefined, instance);
    this.eApi = new DeviceApi(config, undefined, instance);
    this.oApi = new OtcApi(config, undefined, instance);
    this.envApi = new EnvironmentApi(config, undefined, instance);
  }

  getDeploymentPlanReport(
    projectId: number,
    envId: string,
    deploymentPlanId: string,
    format: 'xlsx' | 'pdf',
    reportTitle?: boolean,
    columns?: (
      | 'otc'
      | 'rds'
      | 'deviceDescription'
      | 'softwareComponentName'
      | 'softwareComponentVersion'
      | 'softwareDescription'
      | 'softwareReleaseNotes'
      | 'installationStatus'
      | 'executor'
      | 'installationDate'
      | 'installationNotes'
    )[],
    withNotDeployed?: boolean,
    sign?: boolean,
    sort?: string,
    groupBy?: boolean
  ): Promise<{ streamUri: string; signature?: string }> {
    return new Promise((resolve, reject) => {
      this.dApi
        .getDeploymentPlanReport(projectId, envId, deploymentPlanId, format, reportTitle, columns, withNotDeployed, sign, sort, groupBy)
        .then((res) => {
          const signature = res.headers['x-signature-ecdsa-sha-512'];
          return resolve({ streamUri: res.config.url!, signature });
        })
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryDeploymentPlan(projectId: number, envId: string, deploymentPlanId: string): Promise<DeploymentPlan> {
    return this.genericPromise(this.dApi.getDeploymentPlan(projectId, envId, deploymentPlanId));
  }

  getDeploymentEnvironments(projectId: number) {
    return this.genericPromise(this.envApi.getEnvironments(projectId));
  }

  addDeploymentEnvironment(projectId: number, name: string, description: string) {
    return this.genericPromise(
      this.envApi.addEnvironment(projectId, {
        name,
        description
      })
    );
  }

  getLatestDeploymentPlanOfTheProject(projectId: number) {
    return this.genericPromise(this.dApi.getLatestDeploymentPlanOfTheProject(projectId));
  }

  deleteDeploymentEnvironment(projectId: number, envId: string, mvccId: number) {
    return this.genericPromise(
      this.envApi.deleteEnvironment(projectId, envId, {
        mvccId
      })
    );
  }

  updateDeploymentEnvironment(projectId: number, envId: string, mvccId: number, name: string, description: string) {
    return this.genericPromise(
      this.envApi.updateEnvironment(projectId, envId, {
        name,
        description,
        mvccId
      })
    );
  }

  queryDeploymentPlans(projectId: number, envId: string): Promise<DeploymentPlanMeta[]> {
    return new Promise<DeploymentPlanMeta[]>((resolve, reject) => {
      this.dApi
        .getDeploymentPlans(projectId, envId)
        .then((r) => resolve(r.data))
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryDevices(projectId: number, envId: string): Promise<Device[]> {
    return new Promise<Device[]>((resolve, reject) => {
      this.eApi
        .getDevices(projectId, envId)
        .then((r) => resolve(r.data))
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  queryOtc(projectId: number, envId: string): Promise<Otc[]> {
    return new Promise<Otc[]>((resolve, reject) => {
      this.oApi
        .getOtcs(projectId, envId)
        .then((r) => resolve(r.data))
        .catch(this.errorHandler(reject).bind(this));
    });
  }

  createDeploymentPlan(projectId: number, envId: string, create: DeploymentPlanCreate): Promise<DeploymentPlan> {
    return this.genericPromise(this.dApi.addDeploymentPlan(projectId, envId, create));
  }

  createDevice(projectId: number, envId: string, create: DeviceCreate): Promise<Device> {
    return this.genericPromise(this.eApi.addDevice(projectId, envId, create));
  }

  createOtc(projectId: number, envId: string, create: OtcCreate): Promise<Otc> {
    return this.genericPromise(this.oApi.addOtc(projectId, envId, create));
  }

  lockDeploymentPlan(projectId: number, envId: string, planId: string, lock: DeploymentPlanLock): Promise<DeploymentPlan> {
    return this.genericPromise(this.dApi.lockDeploymentPlan(projectId, envId, planId, lock));
  }

  updateDeploymentPlanDeployment(projectId: number, envId: string, deploymentPlanId: string, update: DeploymentPlanUpdateDeployment): Promise<DeploymentPlan> {
    return this.genericPromise(this.dApi.updateDeployment(projectId, envId, deploymentPlanId, update));
  }

  patchDeploymentPlanDeployment(projectId: number, envId: string, deploymentPlanId: string, update: DeploymentPlanPatchDeployment): Promise<DeploymentPlan> {
    return this.genericPromise(this.dApi.patchDeploymentPlan(projectId, envId, deploymentPlanId, update));
  }

  updateOtc(projectId: number, envId: string, otcId: string, update: Otc): Promise<Otc> {
    return this.genericPromise(this.oApi.updateOtc(projectId, envId, otcId, update));
  }

  updateDevice(projectId: number, envId: string, deviceId: string, update: Device): Promise<Device> {
    return this.genericPromise(this.eApi.updateDevice(projectId, envId, deviceId, update));
  }

  deleteDevice(projectId: number, envId: string, deviceId: string, deviceDelete: DeviceDelete): Promise<void> {
    return this.genericPromise(this.eApi.deleteDevice(projectId, envId, deviceId, deviceDelete));
  }

  deleteOtc(projectId: number, envId: string, otcId: string, otcDelete: OtcDelete): Promise<void> {
    return this.genericPromise(this.oApi.deleteOtc(projectId, envId, otcId, otcDelete));
  }

  onError(handler: (error: DeploymentBackendError) => any): void {
    this.emitter.on('error', handler);
  }

  private errorHandler(rejector: (error: DeploymentBackendError) => any): (error: AxiosError) => any {
    return (error: AxiosError) => {
      const resData = error.response?.data as { message: string; details: { field: string; info: string; value: string }[] | undefined } | undefined | null;
      const resMessage = resData?.message ?? error.message;
      const resDetails: { path: string; message: string; value: string }[] =
        resData?.details?.map((d) => {
          return { message: d.info, path: d.field, value: d.value };
        }) || [];
      const err = new DeploymentBackendError(resMessage, error.response?.status || 999, resDetails);
      this.emitter.emit('error', err);
      rejector(err);
    };
  }

  private genericPromise<T>(promise: Promise<AxiosResponse<T>>) {
    return new Promise<T>((rs, rj) => {
      promise.then((r) => rs(r.data)).catch(this.errorHandler(rj).bind(this));
    });
  }
}

export const useRestDeploymentBackend = (state: GlobalState) => {
  const config = useRestBackendConfig(state.deploymentServiceBasePath);
  const backend = useMemo(() => new RestDeploymentBackend(new Configuration(config), SharedAxiosInstance.instance()), [config]);
  return backend;
};
