import { useMemo } from 'react';
import { Skeleton } from 'antd';
import { useGetProjectSoftwareAppsScoped } from './hooks/useProjectSoftwareApps';
import { SoftwareItemSelection } from '../../../../../domain/softwareAppsSelections';
import { useCommonSoftwareAppsScoped } from './hooks/useCommonSoftwareApps';
import { SoftwareTableProvider } from './components/SoftwareTable/SoftwareTableProvider';
import { useDebounce } from '../../../../shared/hooks/useDebounce';
import { Project, Tool } from '../../../../../api/engineering/domain/types';
import { ScopedSoftwareApp } from '../../types';
import { uniqBy } from 'lodash';
import { useTools } from './hooks/useTools';

type ProjectSoftwareListProps = {
  project: Project;
  selected?: SoftwareItemSelection[];
  initiallySelected: SoftwareItemSelection[];
  showBundleItemsOnly?: boolean;
  loading?: boolean;
  onDirty?: (dirty: boolean) => any;
  onSelect: (selected: SoftwareItemSelection[]) => any;
  readonly?: boolean;
};

export const SoftwareList = (props: ProjectSoftwareListProps) => {
  const projectSoftwareApps = useGetProjectSoftwareAppsScoped(props.project.idProject.toString());
  const commonSoftwareApps = useCommonSoftwareAppsScoped();
  const tools = useTools();

  const isLoading = projectSoftwareApps.isLoading || commonSoftwareApps.isLoading || props.loading || !props.selected;
  const loadingDebounced = useDebounce(isLoading, 100, true);
  const isSuccess = projectSoftwareApps.isSuccess && commonSoftwareApps.isSuccess && !loadingDebounced;

  // Include initially selected items as well, since a user might
  // not have access to common apps/tools but shall see the bundle content
  const softwareItems: (ScopedSoftwareApp | Tool)[] = useMemo(() => {
    const ret: (ScopedSoftwareApp | Tool)[] = [];
    if (projectSoftwareApps.data && projectSoftwareApps.data.length > 0) {
      ret.push(...projectSoftwareApps.data);
    }
    if (commonSoftwareApps.data && commonSoftwareApps.data.length > 0) {
      ret.push(...commonSoftwareApps.data);
    }
    if (tools.data && tools.data.length > 0) {
      ret.push(...tools.data);
    }

    ret.push(
      ...props.initiallySelected.map(
        (softwareItemSelection) => ({ ...softwareItemSelection.softwareItem, latestVersion: softwareItemSelection.version }) as ScopedSoftwareApp | Tool
      )
    );
    // Remove duplicates
    return uniqBy(ret, (softwareItem) =>
      (softwareItem as ScopedSoftwareApp).idSoftwareApp
        ? `${(softwareItem as ScopedSoftwareApp).idSoftwareApp}-${(softwareItem as ScopedSoftwareApp).scope}`
        : (softwareItem as Tool).id
    );
  }, [projectSoftwareApps.data, commonSoftwareApps.data, tools.data, props.initiallySelected]);

  return (
    <div>
      {loadingDebounced && <Skeleton active />}
      {isSuccess && props.selected && <SoftwareTableProvider softwareItems={softwareItems} {...props} selected={props.selected} readonly={props.readonly} />}
    </div>
  );
};
