import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SoftwareApp, SoftwareAppUpdate } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../api/shared/successPresenter';

export function useUpdateCommonSoftwareApp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareApp, any, [SoftwareAppUpdate]>(async ([softwareApp]) => {
    const updatedSoftwareApp = await backend.updateCommonApp(softwareApp);

    queryClient.invalidateQueries(['commonSoftwareApps']);
    queryClient.invalidateQueries(['commonSoftwareApp', updatedSoftwareApp.idSoftwareApp?.toString()]);

    presentSuccess(`Successfully updated Software App ${softwareApp.name}`);

    return updatedSoftwareApp;
  });
}
