import { Card, Row, Col, Typography } from 'antd';
import { useTools } from '../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useTools';
import { useStyles } from '../../theme/useStyles';
import { DashboardLink } from '../../projects/components/DashboardLink';
import { LinkExternal } from '../../../contexts/shared/components/icons/LinkExternal';
import { PrimaryHeader } from '../../../contexts/shared/components/PrimaryHeader';
import { ImpactsDownloadButton } from '../../../contexts/impacts';
import styled from 'styled-components';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { RedirectLink, Redirects } from '@pacts/utils-redirects';

const StyledLink = styled(Typography.Link)`
  color: ${({ theme }) => theme.colorText} !important;

  span {
    margin-right: 8px;
  }
`;

const LinksCard = styled(Card)`
  .ant-card-body {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const UsefulLinks = () => {
  const tools = useTools();
  const token = useStyles();
  const permissions = usePermissions();

  // This does not include impacts download
  // Since ImPacTS downloads should only carried out by internals
  // there is not a known case (yet) where this would be relevant.
  const hasAnyPermission = permissions.webui$showHelp || permissions.webui$showDashboards;
  if (!hasAnyPermission) {
    return null;
  }

  const isLoading = tools.isLoading;

  const HowToLink = (props: { title: string | React.ReactNode; link: RedirectLink | null }) => (
    <Col span={24}>
      <StyledLink target="_blank" href={props.link ?? undefined}>
        <span>{props.title}</span>
        <LinkExternal style={{ color: token.colorLink }} />
      </StyledLink>
    </Col>
  );

  return (
    <LinksCard title={<PrimaryHeader>Useful Links</PrimaryHeader>} loading={isLoading}>
      <Row gutter={[16, 16]}>
        {permissions.webui$showHelp && <HowToLink link={Redirects.SERVICE_DESK} title="ENP Service Desk - Get Support for all ENP Products" />}
        {permissions.webui$showHelp && <HowToLink link={Redirects.WIKI_PACTS} title="PacTS - How-Tos Wiki Page" />}
        {permissions.webui$showHelp && <HowToLink link={Redirects.WIKI_IMPACTS} title="ImPacTS - How-Tos Wiki Page" />}
        <ImpactsDownloadButton type="link" style={{ margin: 0, padding: 0, height: 23, border: 0 }}>
          <HowToLink
            link={null}
            title={
              <>
                Download latest Version of <b>ImPacTS</b>
              </>
            }
          />
        </ImpactsDownloadButton>
        {permissions.webui$showDashboards && (
          <Col span={24}>
            <DashboardLink target={{ name: 'engineering-platform-infrastructure', id: 'platform' }} />
          </Col>
        )}
      </Row>
    </LinksCard>
  );
};
