import { useQuery } from '@tanstack/react-query';
import { BundleRelease, useEngineeringBackend } from '../../../api';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const useLatestProjectBundleConfigurationRelease = (projectId: number) => {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({
    projectId: String(projectId)
  });

  const latestConfigRelease = useQuery<BundleRelease, [string, string]>(
    ['latestBundleRelease', projectId],
    (): Promise<BundleRelease> => {
      return backend.getLatestProjectBundleConfigurationRelease(projectId);
    },
    {
      enabled: projectId > -1 && permissions.engineeringSvc$getLatestProjectBundleConfigurationRelease
    }
  );

  return latestConfigRelease;
};
