import { ComponentProps } from 'react';

import { VulnerabilityIconButton } from '../../../../shared/components/VulnerabilityIconButton';

import { useToolVersionVulnerabilities } from './hooks/useToolVersionVulnerabilities';

import type { Tool, ToolVersion } from '../../../../../api/engineering/domain/types';
import { usePermissions } from '../../../../../contexts/session/hooks/usePermissions';

/**
 * Returns an icon button which opens a drawer that displays
 * the vulnerability notifications.
 */
export const ToolVersionVulnerabilityButton = (props: {
  app: Tool;
  version: ToolVersion;
  getVulnerabilityDrawerContainer?: ComponentProps<typeof VulnerabilityIconButton>['getDrawerContainer'];
}) => {
  const hasPermissions = usePermissions({
    toolId: props.app.id.toString(),
    idSoftwareAppVersion: props.version.idToolVersion.toString()
  }).engineeringSvc$getToolVersionVulnerabilities;

  const hasComponentId = !!props.version.icsPortalComponentId;

  const versionVulnerabilities = useToolVersionVulnerabilities(props.app.id, props.version);

  let notificationsExist = !!versionVulnerabilities.data && !!versionVulnerabilities.data.notifications && versionVulnerabilities.data.notifications.length > 0;

  let vulnerabilities = undefined;

  if (versionVulnerabilities.data) {
    vulnerabilities = [versionVulnerabilities.data];
  }

  if (!hasPermissions) return null;
  if (!hasComponentId) return null;

  return (
    <VulnerabilityIconButton
      appName={props.app.name}
      versionName={props.version.version}
      isLoading={versionVulnerabilities.isLoading}
      isError={versionVulnerabilities.isError}
      error={versionVulnerabilities.error}
      notificationsExist={notificationsExist}
      vulnerabilities={vulnerabilities}
      getDrawerContainer={props.getVulnerabilityDrawerContainer}
    />
  );
};
