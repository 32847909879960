import { ContainerOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useState } from 'react';
import { CommonSoftwareAppVersionContentProvider } from './CommonSoftwareAppVersionContentProvider';
import { ProjectSoftwareAppVersionContentProvider } from './ProjectSoftwareAppVersionContentProvider';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';
import { SoftwareComponent } from '../../../../../api';
import { ToolVersionContentProvider } from './ToolVersionContentProvider';

export const SoftwareItemVersionPreview = (props: { projectId: string; component: SoftwareComponent | null; disabled?: boolean }) => {
  const [previewVisible, setPreviewVisible] = useState(false);

  return (
    <>
      <ShiftedDrawer
        open={previewVisible && props.component != null}
        onClose={() => {
          setPreviewVisible(false);
        }}
        title={`Software details: ${props.component?.name} ${props.component?.version}`}
      >
        {props.component?.type === 'app' && props.component?.scope === 'project' && (
          <ProjectSoftwareAppVersionContentProvider component={props.component} projectId={props.projectId} />
        )}

        {props.component?.type === 'app' && props.component?.scope === 'common' && <CommonSoftwareAppVersionContentProvider component={props.component} />}

        {props.component?.type === 'tool' && <ToolVersionContentProvider component={props.component} />}
      </ShiftedDrawer>

      <Tooltip title="Software details">
        <Button icon={<ContainerOutlined />} disabled={props.disabled ?? false} type="text" onClick={() => setPreviewVisible(true)} />
      </Tooltip>
    </>
  );
};
