import { Project, SoftwareAppVersion } from '../../../../../api/engineering/domain/types';
import { SoftwareItemSelection } from '../../../../../domain';
import { OpenComponentArgs, ToolManagerArgs, ToolManagerLink } from '../../../../../domain/toolmanager/toolmanagerArgs';
import { ExpandableMenuItem } from '../../../../shared/components/ExpandableMenuItem';
import { PacTSIcon } from '../../../../shared/components/icons/PacTSIcon';
import useSubmitMetric from '../../../../metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../../metrics/constants';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import { ScopedSoftwareApp } from '../../types';
import { useArtifactoryLinkPreFlight } from '../../../../shared/components/Download/hooks/useArtifactoryLinkPreFlightCheck';

export const SoftwareAppOpenInToolManagerButton = ({ selection, project }: { selection: SoftwareItemSelection; project?: Project }) => {
  const metrics = useSubmitMetric();
  const preflight = useArtifactoryLinkPreFlight(selection.version);
  const softwareApp = selection.softwareItem as ScopedSoftwareApp;
  const softwareAppVersion = selection.version as SoftwareAppVersion;
  const disabled = softwareAppVersion.state !== 'consistent' || !preflight;
  const permissions = usePermissions({
    softwareAppId: softwareApp.idSoftwareApp.toString(),
    idSoftwareAppVersion: softwareAppVersion.idSoftwareAppVersion.toString(),
    projectId: project?.idProject.toString()
  });

  if (!permissions.impacts$use) return null;

  const onClick = () => {
    const component: OpenComponentArgs = {
      component: {
        category: softwareApp.categories?.map((c) => c.name!).join(', ') || '',
        id: softwareApp.idSoftwareApp!.toString(),
        name: softwareApp.name,
        scope: softwareApp.scope,
        targets: softwareAppVersion.targets.map((t) => {
          return {
            targetId: t.target.idTarget,
            downloadLink: t.downloadLink,
            targetName: t.target.name,
            sha256: t.sha256
          };
        }),
        type: 'app',
        version: softwareAppVersion.version,
        versionId: softwareAppVersion.idSoftwareAppVersion!.toString(),
        context: project ? { projectId: project.idProject!.toString(), projectName: project.name } : undefined
      }
    };
    const args: ToolManagerArgs = {
      action: 'openComponent',
      payload: component
    };
    ToolManagerLink.open(args);

    if (project) {
      metrics
        .submitClick({
          operationId: MetricClickIds.openInImpacts
        })
        .withAppVersionContext(softwareApp, softwareAppVersion, project);
    } else {
      metrics
        .submitClick({
          operationId: MetricClickIds.openInImpacts
        })
        .withCommonAppVersionContext(softwareApp, softwareAppVersion);
    }
  };

  return (
    <>
      <ExpandableMenuItem disabled={disabled} icon={<PacTSIcon disabled={disabled} />} onClick={onClick}>
        Open in ImPacTS
      </ExpandableMenuItem>
    </>
  );
};
