import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tool, ToolVersion } from '../../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../../api/shared/successPresenter';

export function useDeleteToolVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [Tool, ToolVersion]>(([tool, toolVersion]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteToolVersion(tool.id.toString(), toolVersion.idToolVersion.toString())
        .then(() => {
          queryClient.invalidateQueries(['engineeringToolVersions', tool.id!.toString()]);
          queryClient.invalidateQueries(['engineeringTool', tool.id!.toString()]);
          presentSuccess(`Successfully deleted Engineering Tool Version ${toolVersion.version}`);
          return resolve();
        })
        .catch(reject);
    });
  });
}
