import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useUpdateProjectSoftwareAppVersion } from './hooks/useUpdateProjectSoftwareAppVersion';
import { ReleaseNotesValidator } from '../../../../../../../domain/validation/releaseNotesValidator';
import { VersionValidator } from '../../../../../../../domain/validation/versionValidator';
import { Sha256Validator } from '../../../../../../../domain/validation/sha256Validator';
import { RELEASE_NOTES_PLACEHOLDER } from '../../../../../../../constants/texts';
import { usePermissions } from '../../../../../../session/hooks/usePermissions';

import { ICSPortalComponentIdFormItem, ICS_PORTAL_ID_NAME_KEY } from '../../../../../../shared/components/Forms/ICSPortalComponentIdFormItem';
import { ExpandableMenuItem } from '../../../../../../shared/components/ExpandableMenuItem';
import { MarkdownFormItem } from '../../../../../../shared/components/MarkdownFormItem';
import { SoftwareApp, SoftwareAppVersion } from '../../../../../../../api/engineering/domain/types';
import { BlobUploadFormItem } from '../../../../../../shared/components/BlobUploadFormItem';
import { ShiftedDrawer } from '../../../../../../shared/components/ShiftedDrawer';
import { ChecksumHowToFormLabel } from '../../../../../../shared/components/ChecksumHowToFormLabel';

export type UpdateSoftwareAppVersionProps = {
  projectId: string;
  parentApp: SoftwareApp;
  softwareAppVersion: SoftwareAppVersion;
};

export const EditProjectSoftwareAppVersion = (props: UpdateSoftwareAppVersionProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const updateSoftwareAppVersion = useUpdateProjectSoftwareAppVersion();
  const [form] = Form.useForm();
  const permission = usePermissions({
    softwareAppId: props.parentApp.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.softwareAppVersion.idSoftwareAppVersion.toString(),
    projectId: props.projectId
  });

  const disabled = props.softwareAppVersion.state !== 'consistent';

  const onSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (updateSoftwareAppVersion.isSuccess) {
      setModalVisible(false);
      form.resetFields();
    }
  }, [form, updateSoftwareAppVersion.isSuccess]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
  };

  if (!permission.engineeringSvc$updateProjectSoftwareAppVersion) return null;

  return (
    <>
      <ExpandableMenuItem
        icon={<EditOutlined />}
        disabled={disabled}
        loading={updateSoftwareAppVersion.isLoading}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        Edit
      </ExpandableMenuItem>

      <ShiftedDrawer
        title="Edit version"
        destroyOnClose
        loading={updateSoftwareAppVersion.isLoading}
        open={modalVisible}
        isFormDrawer
        onOk={onSubmit}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ ...props.softwareAppVersion }}
          form={form}
          labelAlign="left"
          onFinish={(value) => {
            const copy = { ...value };
            updateSoftwareAppVersion.mutate([props.projectId, props.parentApp.idSoftwareApp.toString(), Object.assign(props.softwareAppVersion, copy)]);
          }}
        >
          <Form.Item label="Version" name="version" required rules={[VersionValidator.rule()]}>
            <Input />
          </Form.Item>
          <MarkdownFormItem
            rules={[ReleaseNotesValidator.rule()]}
            label="Release Notes"
            field={['releaseNotes']}
            placeholder={RELEASE_NOTES_PLACEHOLDER}
            shouldUpdate={(prevValues, currentValues) => prevValues.releaseNotes !== currentValues.releaseNotes}
          />
          <Form.List name="targets">
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <React.Fragment key={index}>
                      <BlobUploadFormItem
                        allowArtifactoryLinks
                        label={`Download Link ${form.getFieldValue(['targets', index, 'target', 'name'])}`}
                        field={['targets', index, 'downloadLink']}
                        relativeFieldInList={[field.key.toString(), 'downloadLink']}
                        rules={[{ required: true, message: 'Required' }]}
                        key={form.getFieldValue(['targets', index, 'target', 'name'])}
                        shouldUpdate={(prevValues, currentValues) => {
                          return prevValues.targets[index].impacts$updateDeploymentLink !== currentValues.targets[index].impacts$updateDeploymentLink;
                        }}
                      />
                      <Form.Item
                        label={<ChecksumHowToFormLabel target={form.getFieldValue(['targets', index, 'target', 'name'])} />}
                        name={[index, 'sha256']}
                        required={false}
                        rules={[Sha256Validator.rule(() => form.getFieldValue(['targets', index, 'downloadLink']))]}
                      >
                        <Input />
                      </Form.Item>

                      <ICSPortalComponentIdFormItem target={form.getFieldValue(['targets', index, 'target', 'name'])} name={[index, ICS_PORTAL_ID_NAME_KEY]} />
                    </React.Fragment>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </ShiftedDrawer>
    </>
  );
};
