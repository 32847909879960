import { useState, useRef, useMemo, useEffect } from 'react';
import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';
import styled from 'styled-components';

import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { THEMES, useStyles, useTheme } from '../../theme';
import CenteredSpace from './CenteredSpace/CenteredSpace';
import * as Styled from '../base/Components.styled';
import { InfoTooltip } from './InfoTooltip';
import { useCallbackOnUnmount } from '../hooks/useCallbackOnUnmount';

const FlexColDiv = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledInput = styled(Input)`
  width: 190px;
  display: block;
`;

type Searchable = string | number | boolean | bigint;
type SearchValueProviderFunc<T> = (value: T) => string;
type DataIndexSearch<T> = string | SearchValueProviderFunc<T>;

type TableSearchProps<T> = {
  searchParamId?: string;
  searchValueProvider: DataIndexSearch<T>;
  keepSearchParameterOnUnload?: boolean;
};

export const useTableSearch = <T extends { [x: string]: any }>(props: TableSearchProps<T>) => {
  const styles = useStyles();
  const [currentTheme] = useTheme();
  const tooltipTextColor = currentTheme === THEMES.LIGHT ? styles.colorText : null;
  const [searchParam, setSearchParam] = useSearchParameter(props.searchParamId || v4());
  const [searchState, setSearchState] = useState({
    searchText: searchParam,
    searchedColumn: props.searchValueProvider
  });

  useEffect(() => {
    if (props.searchParamId) setSearchParam(searchState.searchText || '');
  }, [searchState, props.searchParamId, setSearchParam]);

  const searchInput = useRef<any>();

  const clearFiltersFunc = useRef<() => any>();

  // use the table's filter icon as ref to calculate the reset for the search params
  const [filterIconRef, setFilterIconRef] = useState<HTMLElement | null>(null);

  // reset the search state and search param
  // in order to make sure that other hooks won't
  // repopulate any values
  const resetSearchParams = () => {
    setSearchState((current) => ({ ...current, searchText: '' }));
    setSearchParam('');
    clearFiltersFunc.current?.();
    // call reset a second time delayed
    // since antd does some strange rendering
    // when opened initially
    setTimeout(() => {
      clearFiltersFunc.current?.();
    }, 10);
  };

  useCallbackOnUnmount(filterIconRef, resetSearchParams, !props.keepSearchParameterOnUnload);

  return useMemo(() => {
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();

      setSearchState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex
      });
    };
    const handleReset = (clearFilters: any) => {
      clearFilters();
      setSearchState({ ...searchState, searchText: '' });
    };
    const getColumnSearchProps = (dataIndex: DataIndexSearch<T>) => ({
      defaultFilteredValue: searchParam ? [searchParam] : [],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
        clearFiltersFunc.current = () => {
          handleReset(clearFilters);
          handleSearch([], confirm, dataIndex);
        };

        return (
          <FlexColDiv>
            <div>
              <Space>
                <Styled.Text type="secondary">Wildcards supported</Styled.Text>
                <InfoTooltip
                  text={
                    <Space direction="vertical">
                      <Styled.Text color={tooltipTextColor}>
                        Wildcards can be used to replace parts of your search value with unknowns. The following wildcards are supported:
                      </Styled.Text>
                      <Space>
                        <Styled.Text strong code color={tooltipTextColor}>
                          *
                        </Styled.Text>
                        <Styled.Text color={tooltipTextColor}>Multiple Characters</Styled.Text>
                      </Space>
                      <Space>
                        <Styled.Text strong code color={tooltipTextColor}>
                          ?
                        </Styled.Text>
                        <Styled.Text color={tooltipTextColor}>Zero or One Character</Styled.Text>
                      </Space>
                    </Space>
                  }
                />
              </Space>

              <StyledInput
                ref={(node) => {
                  if (node) searchInput.current = node;
                }}
                placeholder="Search"
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              />
            </div>
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  handleReset(clearFilters);
                  handleSearch([], confirm, dataIndex);
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </FlexColDiv>
        );
      },
      filterIcon: (filtered: boolean) => {
        return (
          <CenteredSpace
            id="tour-element1"
            style={{
              fontSize: styles.fontSizeLG,
              backgroundColor: filtered ? styles.colorPrimary : 'transparent',
              borderRadius: 32,
              width: 32,
              height: 32,
              margin: '0 -4px'
            }}
          >
            <SearchOutlined
              ref={(ref) => setFilterIconRef(ref)}
              id={`table-search-${props.searchValueProvider}`}
              style={{ color: filtered ? styles.colorWhite : undefined }}
            />
          </CenteredSpace>
        );
      },
      // eslint-disable-next-line
      onFilter: (value: Searchable, record: T) => {
        let recordString = '';
        const dataIndexIsString = typeof dataIndex === 'string' || dataIndex instanceof String;
        if (!dataIndexIsString) {
          recordString = (dataIndex as SearchValueProviderFunc<T>)(record);
        } else {
          recordString = dataIndex
            .split('.')
            .reduce((o: { [x: string]: any }, i: string | number) => o[i], record)
            .toString();
        }

        if (recordString) {
          const escapedRegex = value
            .toString()
            .trim()
            .toLowerCase()
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
            .replace(/\\\*/g, '.*')
            .replace(/\\\?/g, '.?');
          const regex = RegExp(escapedRegex);
          const testResult = regex.test(
            recordString
              .trim()
              .toString()
              .toLowerCase()
              .replace(/\s{2,}/g, ' ')
          );
          return testResult;
        }
        return false;
      },
      onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      }
    });
    return getColumnSearchProps(props.searchValueProvider);
  }, [props.searchValueProvider, searchState, searchParam, styles.colorPrimary, styles.colorWhite, styles.fontSizeLG, tooltipTextColor]);
};
