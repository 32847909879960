import { Typography } from 'antd';
import { useState } from 'react';
import { SoftwareItemSelection } from '../../../../domain';
import { usePermissions } from '../../../session/hooks/usePermissions';
import { AddCommonSoftwareApp } from '../AddSoftwareApp/AddCommonSoftwareApp';
import { CommonSoftwareAppsList } from './CommonSoftwareAppsList';
import { PageHeader } from '@ant-design/pro-layout';
import BreadcrumbsContainer from '../../../projects/components/BreadcrumbsContainer';
import styled from 'styled-components';
import { FlexCardLayout } from '../../../layout/components/FlexCardLayout';

const StyledPageHeader = styled(PageHeader)`
  background-color: ${({ theme }) => theme.colorBgLayout} !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

export const CommonAppsView = () => {
  const [selected, setSelected] = useState([] as SoftwareItemSelection[]);
  const [initialSelection] = useState([] as SoftwareItemSelection[]);
  const permissions = usePermissions();

  return (
    <FlexCardLayout
      header={
        <>
          <BreadcrumbsContainer items={[{ title: 'Software platform' }]} />
          <StyledPageHeader
            title={<Typography.Title level={4}>Software Platform Overview</Typography.Title>}
            extra={[permissions.engineeringSvc$addCommonSoftwareApp && <AddCommonSoftwareApp key="add" />]}
          />
        </>
      }
    >
      <CommonSoftwareAppsList selected={selected} onSelect={setSelected} initiallySelected={initialSelection} />
    </FlexCardLayout>
  );
};
