import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SoftwareAppVersion, SoftwareAppVersionCreate } from '../../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../../api/shared/successPresenter';

export function useCreateCommonSoftwareAppVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareAppVersion, any, [string, SoftwareAppVersionCreate], SoftwareAppVersion[]>(async ([softwareAppId, softwareAppVersion]) => {
    try {
      const swa = await backend.createCommonAppVersion(softwareAppId, softwareAppVersion);
      presentSuccess(`Successfully created Software Application Version ${swa.version}`);

      await Promise.all([
        await queryClient.invalidateQueries(['commonSoftwareApp', softwareAppId]),
        queryClient.invalidateQueries(['commonSoftwareApps']),
        queryClient.invalidateQueries(['commonSoftwareAppVersions', softwareAppId])
      ]);

      return swa;
    } catch (error) {
      console.warn(error);
      throw new Error(error as string);
    }
  });
}
