import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SoftwareApp } from '../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { usePermissions } from '../../../../../session/hooks/usePermissions';
import { useDebounce } from '../../../../../shared/hooks/useDebounce';

export function useProjectSoftwareApp(projectId: string, softwareAppId: string) {
  const { backend } = useEngineeringBackend();
  const parsedBundleId = parseInt(projectId, 10);
  const parsedSoftwareAppId = parseInt(softwareAppId, 10);
  const permissions = usePermissions({ projectId, softwareAppId });

  const toDebounceConfig = useMemo(() => {
    return { projectId, softwareAppId };
  }, [projectId, softwareAppId]);

  const db = useDebounce(toDebounceConfig);

  return useQuery<SoftwareApp, [string, string, string]>(
    ['softwareApp', db.projectId, db.softwareAppId],
    (): Promise<SoftwareApp> => {
      return backend.queryProjectApp(projectId, softwareAppId);
    },
    {
      enabled: parsedBundleId > -1 && parsedSoftwareAppId > -1 && permissions.engineeringSvc$getProjectSoftwareApp
    }
  );
}
