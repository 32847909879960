import { List, Space, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { Project } from '../../../api';
import { useStyles } from '../../theme/useStyles';
import { useSources } from '../../reports/hooks/useSources';
import { ProjectPin } from '../../../contexts/projects/components/ProjectPin';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { AnimatedLoadingIcon } from '../../../contexts/shared/components/icons/AnimatedLoadingIcon';

export const PinnedProjectListItem = (props: { project: Project }) => {
  const token = useStyles();
  const sources = useSources(props.project.idProject);
  const permissions = usePermissions({ projectId: String(props.project.idProject) });
  const hasReportPermissions = permissions.reportSvc$getReportRevisions && permissions.reportSvc$getReportSources;
  const hasBundlePermissions = permissions.engineeringSvc$getProjectBundles || permissions.engineeringSvc$getProjectBundles$specific().length > 0;

  const isLoading = sources.isLoading && hasReportPermissions;
  const reportLinks = hasReportPermissions
    ? (sources.data || []).map((s) => (
        <Link to={`/projects/${props.project.idProject.toString()}/reports/${s.id}/latest`} key={`release-${s.id}`}>
          {s.displayName}
        </Link>
      ))
    : [];
  const linksContent = [
    hasBundlePermissions && (
      <Link to={`/projects/${props.project.idProject.toString()}/project-software/configurations`} key="project-software">
        Project Software
      </Link>
    ),
    <Link to={`/projects/${props.project.idProject.toString()}/deployments/environments`} key="deployments">
      Deployments
    </Link>,
    ...reportLinks
  ].filter(Boolean);

  const actions = isLoading ? [<Spin indicator={<AnimatedLoadingIcon style={{ fontSize: `${token.fontSize}px` }} />} />] : linksContent;

  return (
    <List.Item style={{ padding: 6 }} actions={actions}>
      <Space>
        <ProjectPin projectId={props.project.idProject.toString()} pinStyle={{ color: token.colorText }} />
        <Link style={{ color: token.colorText }} to={`/projects?type=${props.project.projectType.idProjectType}&active=${props.project.idProject?.toString()}`}>
          {props.project.name}
        </Link>
      </Space>
    </List.Item>
  );
};
