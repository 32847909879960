import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tool, ToolVersion, ToolVersionCreate } from '../../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../../api/shared/successPresenter';

export function useCreateToolVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<ToolVersion, any, [Tool, ToolVersionCreate]>(async ([tool, toolVersion]) => {
    const updatedTool = await backend.createToolVersion(tool.id.toString(), toolVersion);
    presentSuccess(`Successfully created Engineering Tool Version ${updatedTool.version}`);

    await queryClient.invalidateQueries(['engineeringTools']);
    await queryClient.invalidateQueries(['engineeringTool', tool.id.toString()]);
    await queryClient.invalidateQueries(['engineeringToolVersions', tool.id.toString()]);

    return updatedTool;
  });
}
