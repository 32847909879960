import { Skeleton } from 'antd';
import { DeploymentPlanMeta } from '../../../api/deployment';
import { useDevices } from '../hooks';
import { useDeploymentPlan } from '../hooks/useDeploymentPlan';
import { DeploymentPlanFlatTable } from './DeploymentPlanFlatTable';
import { Project } from '../../../api';

export const DeploymentPlanCardContent = (props: { meta: DeploymentPlanMeta; project: Project; envId: string; shouldShowPartial: boolean }) => {
  const plan = useDeploymentPlan(props.meta.id, props.envId, props.project.idProject);
  const devices = useDevices(props.envId, props.project.idProject);
  const isLoading = plan.isLoading || devices.isLoading || !plan.data;

  return (
    <>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <DeploymentPlanFlatTable
          key={props.envId}
          shouldShowPartial={props.shouldShowPartial}
          project={props.project}
          envId={props.envId}
          plan={plan.data}
          devices={devices.data || []}
        />
      )}
    </>
  );
};
