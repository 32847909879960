import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BundleReleaseLite } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../api/shared/successPresenter';

export function useCreateConfigurationVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<BundleReleaseLite, any, [string, string, string, BundleReleaseLite]>(([projectId, bundleId, configurationId, bundleReleaseLite]) => {
    return new Promise<BundleReleaseLite>((resolve, reject) => {
      backend
        .createBundleConfigurationVersion(projectId, bundleId, configurationId, bundleReleaseLite)
        .then((brl) => {
          const existing = queryClient.getQueryData<BundleReleaseLite[]>(['bundleConfigurationVersions', projectId, bundleId, configurationId]);
          if (existing) {
            queryClient.setQueryData(['bundleConfigurationVersions', projectId, bundleId, configurationId], [...existing, brl]);
          } else {
            queryClient.setQueryData(['bundleConfigurationVersions', projectId, bundleId, configurationId], [brl]);
          }
          queryClient.invalidateQueries(['bundleConfigurationVersions', projectId, bundleId, configurationId]);
          queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId, configurationId]);
          queryClient.invalidateQueries(['bundleConfiguration', projectId, bundleId, configurationId]);
          presentSuccess(`Successfully created configuration version ${brl.version}`);
          return resolve(brl);
        })
        .catch(reject);
    });
  });
}

export default useCreateConfigurationVersion;
