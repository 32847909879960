import { Space } from 'antd';
import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { SubscriptionPopup } from '../../../../../contexts/notifications/components/SubscriptionPopup';
import { ExpandableMenu } from '../../../../shared/components/ExpandableMenu';
import { ExpandableMenuItem } from '../../../../shared/components/ExpandableMenuItem';
import { DownloadContextMenuButton } from '../../../../shared/components/Download/components/DownloadContextMenuButton';
import { ToolOpenInToolManagerButton } from '../SoftwareItemOpenInToolManagerButton/ToolOpenInToolManagerButton';
import { SoftwareItemSelection } from '../../../../../domain';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import useSubmitMetric from '../../../../metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../../metrics/constants';

import type { Project, Tool, ToolVersion } from '../../../../../api';

interface ToolActionsMenuProps {
  tool: Tool;
  project?: Project;
  selection?: SoftwareItemSelection;
  onOpenDetailsDrawer: (tool: Tool) => void;
  onOpenVersionDrawer: (tool: Tool) => void;
}

export const ToolActionsMenu: React.FC<ToolActionsMenuProps> = (props) => {
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    toolId: props.tool.id.toString(),
    idToolVersion: (props.selection?.version as ToolVersion).idToolVersion.toString()
  });
  const metrics = useSubmitMetric();
  const canGetVersions = permissions.engineeringSvc$getToolVersions || permissions.engineeringSvc$getToolVersion$specific().length > 0;

  const handleOpenVersionDrawer = () => {
    props.onOpenVersionDrawer(props.tool);
  };

  const handleOpenDetailsDrawer = () => {
    props.onOpenDetailsDrawer(props.tool);
  };

  return (
    <>
      <Space>
        <SubscriptionPopup type="text" title={props.tool.name} topics={[{ key: `tools/${props.tool.id}/**`, title: props.tool.name }]} />

        <ExpandableMenu textType id={`engineering-tool-actions-${props.tool.id}`}>
          {permissions.webui$showComponentDetails && (
            <ExpandableMenuItem
              icon={<InfoCircleOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentDetails
                  })
                  .withToolContext(props.tool);

                handleOpenDetailsDrawer();
              }}
            >
              Details
            </ExpandableMenuItem>
          )}
          {canGetVersions && (
            <ExpandableMenuItem
              icon={<OrderedListOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentVersions
                  })
                  .withToolContext(props.tool);

                handleOpenVersionDrawer();
              }}
            >
              Versions
            </ExpandableMenuItem>
          )}
          {props.selection && <DownloadContextMenuButton artifact={props.selection.softwareItem} version={props.selection.version} />}
          {props.selection && <ToolOpenInToolManagerButton selection={props.selection} project={props.project} />}
        </ExpandableMenu>
      </Space>
    </>
  );
};
