import { SoftwareComponent } from '../../../../../api';
import { SoftwareItemVersionPreviewContent } from './SoftwareItemVersionPreviewContent';
import { useTools } from '../../../../ProjectSoftware/ConfigurationDetails/components/SoftwareList/hooks/useTools';
import { useToolVersions } from '../../../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersions/components/SoftwareItemVersionsList/hooks/useToolVersions';

export const ToolVersionContentProvider = ({ component }: { component: SoftwareComponent }) => {
  const tools = useTools();
  const toolVersions = useToolVersions(component.id.toString());
  const currentTool = tools.data?.find((tool) => tool.id === component.id);
  const currentToolVersion = toolVersions.data?.find((toolVersion) => toolVersion.idToolVersion === component.versionId);
  const isLoading = tools.isLoading || toolVersions.isLoading;

  return <SoftwareItemVersionPreviewContent isLoading={isLoading} softwareItem={currentTool} version={currentToolVersion} />;
};
