import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../../../../api/shared/successPresenter';
import { BundleConfiguration, useEngineeringBackend } from '../../../../../../api';

export function useEditConfiguration() {
  const queryClient = useQueryClient();
  const { backend } = useEngineeringBackend();
  return useMutation<BundleConfiguration, any, [string, string, BundleConfiguration]>(([projectId, bundleId, bundleConfiguration]) => {
    return new Promise<BundleConfiguration>((resolve, reject) => {
      backend
        .updateBundleConfiguration(projectId, bundleId, bundleConfiguration)
        .then((bc) => {
          const existing = queryClient.getQueryData<BundleConfiguration[]>(['bundleConfigurations', projectId, bundleId]);
          if (existing) {
            const previous = (queryClient.getQueryData(['bundleConfigurations', projectId, bundleId]) as BundleConfiguration[]).filter(
              (config: BundleConfiguration) => config.idBundleConfiguration !== bc.idBundleConfiguration
            );
            queryClient.setQueriesData(['bundleConfigurations', projectId, bundleId], [...previous, bc]);
          } else {
            queryClient.setQueriesData(['bundleConfigurations', projectId, bundleId], [bc]);
          }
          queryClient.invalidateQueries(['bundleConfigurations', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfiguration', projectId, bundleId, bundleConfiguration.idBundleConfiguration.toString()]);
          presentSuccess(`Successfully updated configuration ${bc.name}`);
          return resolve(bc);
        })
        .catch(reject);
    });
  });
}

export default useEditConfiguration;
