import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';

export function useDeleteProjectSoftwareApp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [string, string]>(([projectId, softwareAppId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteProjectSoftwareApp(projectId, softwareAppId)
        .then(() => {
          queryClient.invalidateQueries(['softwareApps', projectId]);
          presentSuccess('Successfully deleted Software App');
          return resolve();
        })
        .catch(reject);
    });
  });
}
