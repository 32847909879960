import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BundleReleaseLite, BundleReleaseNotesPut } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../api/shared/successPresenter';

export function useUpdateConfigurationVersionReleaseNotes() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<BundleReleaseLite, any, [string, string, string, string, BundleReleaseNotesPut]>(
    ([projectId, bundleId, configurationId, versionId, releaseNotes]) => {
      return new Promise<BundleReleaseLite>((resolve, reject) => {
        backend
          .updateConfigurationVersionReleaseNotes(projectId, bundleId, configurationId, versionId, releaseNotes)
          .then((brl) => {
            queryClient.invalidateQueries(['bundleConfigurationVersions', projectId, bundleId, configurationId]);
            queryClient.invalidateQueries(['bundleConfigurationVersion', projectId, bundleId, configurationId]);
            queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId, configurationId]);
            queryClient.invalidateQueries(['bundleConfiguration', projectId, bundleId, configurationId]);
            presentSuccess(`Successfully updated configuration version ${brl.version}'s release notes`);
            return resolve(brl);
          })
          .catch(reject);
      });
    }
  );
}

export default useUpdateConfigurationVersionReleaseNotes;
