import { useMutation, useQueryClient } from '@tanstack/react-query';
import { presentSuccess } from '../../../../../../api/shared/successPresenter';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { BundleConfiguration } from '../../../../../../api/engineering/domain/types';

export function useDeleteBundleConfiguration() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<any, any, [string, string, string]>(([projectId, bundleId, configurationId]) => {
    return new Promise<void>((resolve, reject) => {
      backend
        .deleteBundleConfiguration(projectId, bundleId, configurationId)
        .then(() => {
          const allData = queryClient.getQueryData<BundleConfiguration[]>(['bundleConfigurations', projectId, bundleId]);
          const existing = allData?.find((d) => d.idBundleConfiguration!.toString() === configurationId);
          if (existing && allData) {
            const newData = [...allData];
            const index = allData.indexOf(existing);
            newData.splice(index, 1);
            queryClient.setQueryData(['bundleConfigurations', projectId, bundleId], newData);
          }

          queryClient.invalidateQueries(['bundleConfigurations', projectId, bundleId]);
          queryClient.invalidateQueries(['bundleConfigVersionData', projectId, bundleId]);
          presentSuccess('Successfully deleted configuration');
          return resolve();
        })
        .catch(reject);
    });
  });
}

export default useDeleteBundleConfiguration;
