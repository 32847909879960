import { ComponentProps } from 'react';

import { VulnerabilityIconButton } from '../../../../shared/components/VulnerabilityIconButton';

import { useProjectSoftwareAppVersionVulnerabilities } from './hooks/useProjectSoftwareAppVersionVulnerabilities';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../../../api/engineering/domain/types';
import { usePermissions } from '../../../../../contexts/session/hooks/usePermissions';

/**
 * Returns an icon button which opens a drawer that displays
 * the vulnerability notifications.
 */
export const ProjectSoftwareAppVersionVulnerabilityButton = (props: {
  project: Project;
  app: SoftwareApp;
  version: SoftwareAppVersion;
  getVulnerabilityDrawerContainer?: ComponentProps<typeof VulnerabilityIconButton>['getDrawerContainer'];
}) => {
  const hasPermissions = usePermissions({
    softwareAppId: props.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.version.idSoftwareAppVersion.toString()
  }).engineeringSvc$getProjectSoftwareAppVersionVulnerabilities;

  const hasComponentId = props.version.targets.some((t) => !!t.icsPortalComponentId);

  const versionVulnerabilities = useProjectSoftwareAppVersionVulnerabilities(props.project.idProject, props.app.idSoftwareApp, props.version);

  if (versionVulnerabilities.data?.length === 0) {
    return null;
  }

  const notificationsExist = !!versionVulnerabilities.data?.some((v) => v.notifications && v.notifications.length > 0);

  if (!hasPermissions) return null;
  if (!hasComponentId) return null;

  return (
    <VulnerabilityIconButton
      appName={props.app.name}
      versionName={props.version.version}
      isLoading={versionVulnerabilities.isLoading}
      isError={versionVulnerabilities.isError}
      error={versionVulnerabilities.error}
      notificationsExist={notificationsExist}
      vulnerabilities={versionVulnerabilities.data}
      appVersion={props.version}
      getDrawerContainer={props.getVulnerabilityDrawerContainer}
    />
  );
};
