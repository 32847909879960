import { Descriptions, Skeleton } from 'antd';
import { SoftwareApp, SoftwareAppVersion, Tool, ToolVersion } from '../../../../../api/engineering/domain/types';
import { MarkdownRenderer } from '../../../../shared/components/MarkdownRenderer';

export const SoftwareItemVersionPreviewContent = ({
  softwareItem,
  isLoading,
  version
}: {
  softwareItem?: SoftwareApp | Tool;
  version?: SoftwareAppVersion | ToolVersion;
  isLoading: boolean;
}) => {
  return (
    <>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <Descriptions bordered column={1} labelStyle={{ width: '240px' }}>
          <Descriptions.Item label="Software">{softwareItem?.name}</Descriptions.Item>
          <Descriptions.Item label="Description">
            <MarkdownRenderer>{softwareItem?.description || ''}</MarkdownRenderer>
          </Descriptions.Item>
          <Descriptions.Item label="Version">{version?.version}</Descriptions.Item>
          <Descriptions.Item label="Release Notes">
            <MarkdownRenderer>{version?.releaseNotes || 'unknown'}</MarkdownRenderer>
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
