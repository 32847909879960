import { ImportOutlined } from '@ant-design/icons';
import { Button, Popover, Space, Typography } from 'antd';
import { useState } from 'react';
import { presentAppError } from '../../../api/shared/errorPresenter';
import { useCreateOtc } from '../hooks/useCreateOtc';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { useParams } from 'react-router-dom';

export const OtcCsvPopover = (props: { projectId: number }) => {
  const params = useParams();
  const envId = params?.envId ?? '';
  const createOtc = useCreateOtc(props.projectId, envId);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const close = () => {
    setPopoverVisible(false);
    setInput('');
  };
  return (
    <Popover
      content={
        <>
          <DescriptionTextAreaInput value={input} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => setInput(ev.target.value)} />
          <Space style={{ width: '100%' }}>
            <Button
              block
              type="link"
              loading={isLoading}
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
            <Button
              type="link"
              loading={isLoading}
              block
              onClick={() => {
                const lines = input
                  .split('\n')
                  .map((v) => v.trim())
                  .filter((v) => v);
                let i = 0;
                setIsLoading(true);

                const createLoop = () => {
                  setTimeout(() => {
                    if (i >= lines.length) {
                      setIsLoading(false);
                      return;
                    }
                    const line = lines[i];
                    const splitted = line.split(',');
                    if (splitted.length !== 2) {
                      presentAppError(new Error(`invalid csv item, must be > otc,description\\n < - is ${line}`));
                      setIsLoading(false);
                      return;
                    }
                    createOtc.mutate([
                      {
                        description: splitted[1].trim(),
                        id: '',
                        mvccId: 0,
                        name: splitted[0].trim(),
                        createdAt: '',
                        createdBy: 0,
                        createdByName: '',
                        updatedAt: '',
                        updatedBy: 0,
                        updatedByName: ''
                      }
                    ]);
                    i++;
                    createLoop();
                  }, 100);
                };
                createLoop();
                close();
              }}
            >
              OK
            </Button>
          </Space>
        </>
      }
      title={
        <Space direction="vertical">
          <span>Import OTCs from CSV</span>
          <Typography.Text type='warning'>We recommend copying Devices and OTCs from the same Environment.</Typography.Text>
        </Space>
      }
      trigger="click"
      open={popoverVisible}
    >
      <Button loading={isLoading} onClick={() => setPopoverVisible(true)} icon={<ImportOutlined />}>
        Import CSV
      </Button>
    </Popover>
  );
};

export default OtcCsvPopover;
