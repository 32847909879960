import { Navigate } from 'react-router-dom';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';
import useBundles from '../../bundles/hooks/useBundles';
import useConfigurations from '../../ProjectSoftware/Configurations/hooks/useConfigurations';

const ProjectSoftwareRedirect: React.FC = () => {
  const [projectId] = useSearchParameter('projectId');
  const [dialogType] = useSearchParameter('dialog');
  const [appId] = useSearchParameter('appId');
  const [appName] = useSearchParameter('appName');
  const bundles = useBundles(projectId ?? '-1');

  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes('Project Software'));
  const bundleId = usedBundle?.idBundle?.toString() || '-1';

  const configurations = useConfigurations(projectId ?? '-1', bundleId);
  const firstConfig = configurations.data?.sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()))[0].idBundleConfiguration;

  // use "ap" as prefix since this is used for project apps only
  // see "scopedAppHash"
  const navigationLink = `/projects/${projectId}/project-software/configurations/${firstConfig}?show=all&active=${projectId}&name=${appName}&open_app_${dialogType}=ap${appId}`;

  if (configurations.isLoading) {
    return <PageLoading />;
  }

  if (!configurations.data || configurations.isError) {
    console.warn('configurations could not be retrieved', configurations);
    return <Navigate to="/404" replace />;
  }

  if (!appId || !projectId || !configurations.data?.length) {
    // if no configuration are found found navigate to home
    // with open notifications panel
    return <Navigate to="/?showNotifications=true" replace />;
  }

  return <Navigate to={navigationLink} replace />;
};

export default ProjectSoftwareRedirect;
