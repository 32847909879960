import { Button, Divider, Flex, Skeleton, Steps, Typography } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Comparator, SoftwareItemSelection } from '../../../domain';
import { formItemLayout } from '../../layout/components/formLayout';
import { useDeploymentPlan } from '../hooks/useDeploymentPlan';
import { useDeploymentPlans } from '../hooks/useDeploymentPlans';
import { useDevices } from '../hooks/useDevices';
import { Device, Environment, SoftwareApp, Tool } from '../../../api';
import styled from 'styled-components';
import { MarkdownPreview } from '../../../contexts/shared/components/MarkdownPreview';
import { UseQueryResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import DeploymentDetailsForm from './DeploymentDetailsForm';
import DeploymentSelectionForm from './DeploymentSelectionForm';
import { NoEnvironmentsMessage } from './NoEnvironmentsMessage';
import useConfigurationVersion from '../../ProjectSoftware/ConfigurationDetails/hooks/useConfigurationVersion';
import { ScopedSoftwareApp, scopeToCommon, scopeToProject } from '../../ProjectSoftware/ConfigurationDetails/types';
import { componentHash } from '../helper/componentHash';
import { softwareItemHash } from '../../ProjectSoftware/ConfigurationDetails/utils/softwareItemHash';

export type InstallerFormProps = {};

enum WizardSteps {
  Details = 0,
  Selection = 1,
  Summary = 2
}

type DeploymentPrototype = {
  selection: SoftwareItemSelection;
  device: Device[];
};

export type DeploymentPlanPrototype = {
  name: string;
  description: string;
  deployments: DeploymentPrototype[];
  envId: string;
};

const StepTitleLabel = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.fontSize}px;
  cursor: pointer;
`;

const StepDescriptionLabel = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.fontSize}px;
  cursor: pointer;
  color: ${({ theme }) => theme.colorTextSecondary};
`;

const StepWrapper = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 386px);
  margin-bottom: 60px;
`;

const FooterWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 15px;

  .ant-divider {
    margin-bottom: 15px !important;
  }
`;

const FormFlexContainer = styled(Flex)`
  min-height: calc(100vh - 194px) !important;
  position: relative !important;
  margin-bottom: -24px !important;
`;

export const CreateDeploymentPlanForm = (props: {
  projectId: number;
  bundleId: number;
  bundleConfigId: number;
  bundleConfigVersionId: number;
  isCreateButtonLoading: boolean;
  envs: UseQueryResult<Environment[], unknown>;
  onFinish: (data: DeploymentPlanPrototype) => Promise<void>;
  form?: FormInstance;
}) => {
  const environments = props.envs.data?.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name));
  const navigate = useNavigate();
  const [submitValue, setSubmitValue] = useState<Record<string, any>>({
    // select the first environment by default
    envId: environments?.length === 1 ? environments?.[0]?.id : undefined
  });
  const envId: string = submitValue?.envId;
  const deploymentPlans = useDeploymentPlans(props.projectId, envId);
  const [currentStep, setCurrentStep] = useState(WizardSteps.Details);
  const overrideRedirectStep = useRef<number | null>(null);
  const [isSecondFormAlert, setIsSecondFormAlert] = useState(false);
  const baseRelease = useConfigurationVersion(
    props.projectId.toString(),
    props.bundleId.toString(),
    props.bundleConfigId.toString(),
    props.bundleConfigVersionId.toString()
  );
  const baseReleaseSoftwareItems = useMemo(
    () =>
      [
        ...(baseRelease.data?.projectSoftwareApps ?? []).map(scopeToProject),
        ...(baseRelease.data?.softwareApps ?? []).map(scopeToCommon),
        ...(baseRelease.data?.engineeringTools ?? [])
      ].sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name)),
    [baseRelease.data]
  );
  const [selectedComponents, setSelectedComponents] = useState(baseReleaseSoftwareItems.map(softwareItemHash));
  const notSelectedSoftwareItems = baseReleaseSoftwareItems.filter((sw) => !selectedComponents.includes(softwareItemHash(sw)));
  const isFirstStep = currentStep === WizardSteps.Details;
  const isLastStep = currentStep === WizardSteps.Summary;
  const [firstForm] = useForm<DeploymentPlanPrototype>(props.form);
  const [secondForm] = useForm<DeploymentPlanPrototype>(props.form);

  const devices = useDevices(envId, props.projectId);
  const sortedDevices = useMemo(() => devices.data?.sort((a, b) => Comparator.lexicographicalComparison(a.rds, b.rds)) || [], [devices.data]);
  const activePlans =
    deploymentPlans.data?.sort((a, b) => {
      return Date.parse(b.createdAt!) - Date.parse(a.createdAt!);
    }) || [];
  const activePlanMeta = activePlans.length > 0 ? activePlans[0] : undefined;

  const activePlan = useDeploymentPlan(activePlanMeta?.id || '', envId, props.projectId);

  const isLoaded = (activePlan.isSuccess || !activePlanMeta?.id) && devices.isSuccess && baseRelease.isSuccess;
  const isFirstScreenLoaded = baseRelease.isSuccess && props.envs.isSuccess;

  // In case the env id changes we have to reset the deployments so the second form initials are renewed.
  // This hook has to be registered before the second form initials memo.
  useEffect(() => {
    setSubmitValue({ ...submitValue, deployments: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envId, setSubmitValue]);

  const secondFormInitials = useMemo(() => {
    if (!isLoaded) {
      return undefined;
    }

    if (submitValue.deployments) {
      return {
        deployments: submitValue.deployments
      } as DeploymentPlanPrototype;
    }

    return {
      deployments: baseReleaseSoftwareItems.map((s) => {
        const previousDeployedDevices: Device[] = [];
        if (activePlan.data) {
          activePlan.data.deployments!.forEach((deployment) => {
            const hasSoftwareComponents = deployment.components.some((sw) => sw.id === (sw.type === 'app' ? (s as SoftwareApp).idSoftwareApp : (s as Tool).id));
            const deviceExists = sortedDevices?.map((d) => d.id).includes(deployment.device.id);
            if (hasSoftwareComponents && deviceExists) {
              previousDeployedDevices.push(deployment.device);
            }
          });
        }
        const proto: DeploymentPrototype = {
          selection: {
            softwareItem: s,
            versionId: (s as ScopedSoftwareApp).latestVersion.idSoftwareAppVersion
              ? String((s as ScopedSoftwareApp).latestVersion.idSoftwareAppVersion)
              : String((s as Tool).latestVersion.idToolVersion),
            version: s.latestVersion
          },
          device: previousDeployedDevices
        };
        return proto;
      })
    } as DeploymentPlanPrototype;
    // do not react when submit value deployments changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlan, sortedDevices, isLoaded, baseReleaseSoftwareItems, envId]);

  const isLoading =
    (activePlan.isLoading && !!activePlanMeta) ||
    props.envs.isLoading ||
    (devices.isLoading && !!envId) ||
    baseRelease.isLoading ||
    (deploymentPlans.isLoading && !!envId);

  const isError = activePlan.isError || props.envs.isError || devices.isError || baseRelease.isError || deploymentPlans.isError;

  const hasNoEnvironments = props.envs.isSuccess && (props.envs.data?.length ?? 0) < 1;

  const isNextButtonDisabled = (currentStep === 1 && !selectedComponents.length) || (currentStep === 1 && !secondFormInitials) || hasNoEnvironments;

  const firstFormInitials = useMemo(() => {
    if (!isFirstScreenLoaded) {
      return undefined;
    }

    return {
      name: submitValue?.name || '',
      envId: submitValue?.envId || undefined,
      description: submitValue?.description || baseRelease.data.releaseNotes
    } as unknown as DeploymentPlanPrototype;
  }, [baseRelease.data?.releaseNotes, isFirstScreenLoaded, submitValue]);

  const handleCreateDeployment = async () => {
    const filteredSubmitValue = {
      ...submitValue,
      deployments: submitValue.deployments?.filter((dep: DeploymentPrototype) => selectedComponents.includes(softwareItemHash(dep.selection.softwareItem)))
    };

    await props.onFinish(filteredSubmitValue as any);
  };

  const toggleAlertCheck = (formErrors: Array<{ errors?: string[] }>) => {
    const isErrorsPropagated = formErrors.some((err) => err?.errors?.length);

    if (isErrorsPropagated) {
      setIsSecondFormAlert(true);
    } else {
      setIsSecondFormAlert(false);
    }
  };

  const validateCurrentStepForm = () => {
    if (isLastStep) {
      return;
    }

    switch (currentStep) {
      case WizardSteps.Details:
        firstForm.submit();
        break;
      case WizardSteps.Selection:
        secondForm
          .validateFields()
          .then(() => {
            secondForm.submit();
          })
          .catch((err) => {
            toggleAlertCheck(err?.errorFields || []);
          });
        break;
      default:
        break;
    }
  };

  const handleNextStep = () => {
    if (isLastStep) {
      handleCreateDeployment();
      return;
    }

    validateCurrentStepForm();
  };

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handlePrevStep = () => {
    if (isFirstStep) {
      return;
    }

    setCurrentStep((prev) => prev - 1);
  };

  const redirectToFollowingStep = () => {
    //  If step redirection comes from step and not submit button
    if (overrideRedirectStep.current != null) {
      setCurrentStep(overrideRedirectStep.current);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const createFormByCurrentStep = (step: number) => {
    switch (step) {
      case WizardSteps.Details:
        return (
          <DeploymentDetailsForm
            environments={props.envs}
            firstForm={firstForm}
            firstFormInitials={firstFormInitials}
            formItemLayout={formItemLayout}
            redirectToFollowingStep={redirectToFollowingStep}
            setSubmitValue={setSubmitValue}
            submitValue={submitValue}
          />
        );
      case WizardSteps.Selection:
        return (
          <DeploymentSelectionForm
            devices={devices}
            formItemLayout={formItemLayout}
            isSecondFormAlert={isSecondFormAlert}
            redirectToFollowingStep={redirectToFollowingStep}
            secondForm={secondForm}
            secondFormInitials={secondFormInitials}
            selectedComponents={selectedComponents}
            setSelectedComponents={setSelectedComponents}
            setSubmitValue={setSubmitValue}
            sortedDevices={sortedDevices}
            toggleAlertCheck={toggleAlertCheck}
          />
        );
      case WizardSteps.Summary:
        return (
          <div style={{ paddingLeft: '50px' }}>
            <Flex style={{ margin: '50px 0' }}>
              <Typography.Text style={{ width: '167px' }}>Environments</Typography.Text>
              <Typography.Text>{environments?.find((env) => env.id === submitValue?.envId)?.name || '-'}</Typography.Text>
            </Flex>
            <Flex style={{ margin: '50px 0' }}>
              <Typography.Text style={{ width: '167px' }}>Name</Typography.Text>
              <Typography.Text>{submitValue?.name || '-'}</Typography.Text>
            </Flex>
            <Flex style={{ margin: '50px 0' }}>
              <Typography.Text style={{ width: '167px' }}>Description</Typography.Text>
              <Typography.Text>
                {submitValue?.description != null ? <MarkdownPreview content={submitValue?.description} title="Description" /> : '-'}
              </Typography.Text>
            </Flex>
            {notSelectedSoftwareItems.length > 0 && (
              <Flex style={{ margin: '50px 0' }}>
                <Typography.Text style={{ width: '167px' }}>Not deployed software</Typography.Text>
                <Typography.Text>
                  <ul>
                    {notSelectedSoftwareItems.map((sw) => (
                      <li>
                        {sw.name} {sw.latestVersion.version}
                      </li>
                    ))}
                  </ul>
                </Typography.Text>
              </Flex>
            )}
          </div>
        );
    }
  };

  useEffect(() => {
    if (currentStep === 0) {
      firstForm.resetFields();
    }

    if (currentStep === 1) {
      setIsSecondFormAlert(false);
      secondForm.resetFields();
    }

    if (currentStep !== 1) {
      toggleAlertCheck([]);
    }
  }, [secondForm, firstForm, currentStep, baseReleaseSoftwareItems]);

  useEffect(() => {
    if (baseRelease.isSuccess && activePlan.isSuccess && !baseRelease.isLoading && !activePlan.isLoading) {
      const notDeployedIds = (activePlan.data.notDeployed || []).map(componentHash);
      setSelectedComponents(baseReleaseSoftwareItems.map(softwareItemHash).filter((hash) => !notDeployedIds.includes(hash)));
    }
  }, [baseRelease.isSuccess, baseRelease.isLoading, baseReleaseSoftwareItems, activePlan.data, activePlan.isSuccess, activePlan.isLoading]);

  if (hasNoEnvironments) return <NoEnvironmentsMessage projectId={props.projectId} />;

  return isFirstScreenLoaded ? (
    <FormFlexContainer vertical justify="space-between">
      <div>
        <Steps
          style={{ marginBottom: '60px' }}
          size="small"
          current={currentStep}
          items={[
            {
              title: <StepTitleLabel disabled={currentStep !== 0}>Deployment details</StepTitleLabel>,
              description: <StepDescriptionLabel disabled={currentStep !== 0}>Environment, name, description</StepDescriptionLabel>,
              disabled: true,
              style: {
                cursor: 'initial'
              }
            },
            {
              title: <StepTitleLabel disabled={currentStep !== 1}>Software selection</StepTitleLabel>,
              description: <StepDescriptionLabel disabled={currentStep !== 1}>Software to be deployed</StepDescriptionLabel>,
              disabled: true,
              style: {
                cursor: 'initial'
              }
            },
            {
              title: <StepTitleLabel disabled={currentStep !== 2}>Summary</StepTitleLabel>,
              description: <StepDescriptionLabel disabled={currentStep !== 2}>Deployment check</StepDescriptionLabel>,
              disabled: true,
              style: {
                cursor: 'initial'
              }
            }
          ]}
        />
        <StepWrapper>{createFormByCurrentStep(currentStep)}</StepWrapper>
      </div>
      <FooterWrapper>
        <Divider />
        <Flex justify="space-between">
          <Button danger style={{ width: '150px' }} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Flex gap="10px" justify="center">
            <Button disabled={currentStep === 0} style={{ width: '175px' }} onClick={handlePrevStep}>
              Previous
            </Button>
            <Button
              disabled={isNextButtonDisabled || isError}
              loading={isLoading || (isLastStep ? props.isCreateButtonLoading : false)}
              onClick={handleNextStep}
              style={{ width: '175px' }}
              type="primary"
            >
              {isLastStep ? 'Create deployment' : 'Next'}
            </Button>
          </Flex>
        </Flex>
      </FooterWrapper>
    </FormFlexContainer>
  ) : (
    <Skeleton />
  );
};
