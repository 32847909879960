import { SoftwareItemSelection } from '../../../../../domain';
import { useCreateDeploymentPlan } from '../../../../deployments/hooks/useCreateDeploymentPlan';
import { DeploymentIcon } from '../../../../shared/components/DeploymentIcon';
import { ExpandableMenuItem } from '../../../../shared/components/ExpandableMenuItem';
import { useInAppNavigate } from '../../../../navigation/hooks/useInAppNavigate';
import { useDeploymentEnvironments } from '../../../../deployments/hooks/useDeploymentEnvironments';

export const CreateDeploymentPlan = ({
  disabled,
  projectId,
  bundleId,
  bundleConfigId,
  bundleConfigVersionId,
  noEnvsCallback
}: {
  disabled?: boolean;
  projectId: number;
  bundleId: number;
  bundleConfigId: number;
  bundleConfigVersionId: number;
  selection: SoftwareItemSelection[];
  noEnvsCallback: () => void;
}) => {
  const navigate = useInAppNavigate();
  const createDeploymentPlan = useCreateDeploymentPlan();
  const envs = useDeploymentEnvironments(projectId);
  const hasNoEnvironments = envs.isSuccess && (envs.data?.length ?? 0) < 1;

  return (
    <>
      <ExpandableMenuItem
        icon={<DeploymentIcon />}
        disabled={disabled}
        loading={createDeploymentPlan.isLoading}
        onClick={() =>
          hasNoEnvironments
            ? noEnvsCallback()
            : navigate(
                `/projects/${projectId}/deployments/create` +
                  `?bundleId=${bundleId}&bundleConfigId=${bundleConfigId}&bundleConfigVersionId=${bundleConfigVersionId}`
              )
        }
      >
        Create Deployment Plan
      </ExpandableMenuItem>
    </>
  );
};
