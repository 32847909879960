import { useRef, useEffect } from 'react';
import { useIsVisibleState } from './useIsVisible';

/**
 * Calls the callback function when the component gets unmounted and the ref element has been visible at least once
 * @param ref Element to be tracked
 * @param callback Invoked when element was visible at least once and goes invisible
 * @param enabled Must be set to true in order to enable the trigger
 * @returns {any}
 */
export const useCallbackOnUnmount = (ref: HTMLElement | null, callback: () => void, enabled?: boolean) => {
  // get visible state from intersection observer
  const isVisible = useIsVisibleState(ref);
  // remember, if the component has been visible once
  const hasBeenVisible = useRef(false);

  // if visible changes, set hasBeenVisible to true
  // in case component is visible
  useEffect(() => {
    if (isVisible) {
      hasBeenVisible.current = true;
    }
    // eslint-disable-next-line
  }, [isVisible]);

  // when the component unloads, reset
  // search parameter in case it has been visible
  useEffect(() => {
    // the component is removed from dom
    // only call the callback if the element has been visible
    // at least once to account for loading states
    return () => {
      if (hasBeenVisible.current && enabled) {
        callback();
      }
    };
    // trigger this hook only when the component unloads
    // eslint-disable-next-line
  }, []);
};
