import useSubmitMetric from '../../../../metrics/hooks/useMetrics';
import { Project, Tool, ToolVersion } from '../../../../../api/engineering/domain/types';
import { OpenComponentArgs, ToolManagerArgs, ToolManagerLink } from '../../../../../domain/toolmanager/toolmanagerArgs';
import { ExpandableMenuItem } from '../../../../shared/components/ExpandableMenuItem';
import { PacTSIcon } from '../../../../shared/components/icons/PacTSIcon';
import { MetricClickIds } from '../../../../metrics/constants';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import { SoftwareItemSelection } from '../../../../../domain';

interface ToolsOpenInToolManagerButtonProps {
  selection: SoftwareItemSelection;
  project?: Project;
}

export const ToolOpenInToolManagerButton = ({ selection, project }: ToolsOpenInToolManagerButtonProps) => {
  const tool = selection.softwareItem as Tool;
  const toolVersion = selection.version as ToolVersion;
  const disabled = toolVersion.state !== 'consistent';
  const metrics = useSubmitMetric();
  const permissions = usePermissions({
    toolId: tool.id.toString(),
    idToolVersion: toolVersion.idToolVersion.toString()
  });
  if (!permissions.impacts$use) return null;

  const onClick = () => {
    const component: OpenComponentArgs = {
      component: {
        category: tool.category?.map((c) => c.name!).join(', ') || '',
        id: tool.id!.toString(),
        name: tool.name,
        scope: 'common',
        targets: [
          {
            targetId: -1,
            downloadLink: toolVersion.downloadLink,
            targetName: '',
            sha256: toolVersion.sha256
          }
        ],
        type: 'tool',
        version: toolVersion.version,
        versionId: toolVersion.idToolVersion!.toString(),
        context: project ? { projectId: project.idProject!.toString(), projectName: project.name } : undefined
      }
    };
    const args: ToolManagerArgs = {
      action: 'openComponent',
      payload: component
    };

    metrics
      .submitClick({
        operationId: MetricClickIds.openInImpacts
      })
      .withToolVersionContext(tool, toolVersion);

    ToolManagerLink.open(args);
  };

  return (
    <>
      <ExpandableMenuItem disabled={disabled} icon={<PacTSIcon disabled={disabled} />} onClick={onClick}>
        Open in ImPacTS
      </ExpandableMenuItem>
    </>
  );
};
