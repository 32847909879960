import { Empty, Skeleton, Table, TableColumnProps } from 'antd';
import { useDeploymentPlans } from '../hooks/useDeploymentPlans';
import ClosedDeploymentColumnItem from './ClosedDeploymentColumnItem';
import { DeploymentPlanMeta, Project } from '../../../api';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { getReleaseName } from '../helper/extractor';

const getRowKey = (deployment: DeploymentPlanMeta): string => {
  return `${deployment.id}-${deployment.release.bundleId}-${deployment.release.releaseId}`;
};

export const ClosedDeployments = (props: { project: Project; envId: string }) => {
  const deploymentPlans = useDeploymentPlans(props.project.idProject, props.envId);
  const nullSafePlantList = deploymentPlans.data || [];

  const nameSearch = useTableSearch({ searchValueProvider: 'name', searchParamId: 'cdp_name' });

  const creatorSearch = useTableSearch({ searchValueProvider: 'createdByName', searchParamId: 'cdp_creator' });

  const releaseSearch = useTableSearch({ searchValueProvider: getReleaseName, searchParamId: 'cdp_release' });

  const creationSorter = (a: DeploymentPlanMeta, b: DeploymentPlanMeta) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();

  const closedSorter = (a: DeploymentPlanMeta, b: DeploymentPlanMeta) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();

  const [tablePage, setTablePage] = useSearchParameter('cdp_p', '1');
  const tablePageNumber = parseInt(tablePage || '1');

  const [pageSize, setPageSize] = useSearchParameter('cdp_ps', '50');
  const pageSizeNumber = parseInt(pageSize || '50');

  const lockedPlans =
    nullSafePlantList
      .filter((plan) => plan.locked)
      .sort((a, b) => {
        return Date.parse(b.createdAt!) - Date.parse(a.createdAt!);
      }) || [];

  const columns: TableColumnProps<DeploymentPlanMeta>[] = [
    {
      title: 'Deployment name',
      key: 'name',
      align: 'left',
      ...nameSearch,
      render: (meta: DeploymentPlanMeta) => <ClosedDeploymentColumnItem property="deploymentName" envId={props.envId} project={props.project} meta={meta} />
    },
    {
      title: 'Creator',
      key: 'creator',
      ...creatorSearch,
      render: (meta: DeploymentPlanMeta) => <ClosedDeploymentColumnItem property="creator" envId={props.envId} project={props.project} meta={meta} />
    },
    {
      title: 'Project software release',
      key: 'projectSoftwareRelease',
      ...releaseSearch,
      render: (meta: DeploymentPlanMeta) => (
        <ClosedDeploymentColumnItem property="projectSoftwareRelease" envId={props.envId} project={props.project} meta={meta} />
      )
    },
    {
      title: 'Creation date',
      key: 'creatorDate',
      sorter: creationSorter,
      width: 190,
      render: (meta: DeploymentPlanMeta) => <ClosedDeploymentColumnItem property="creationDate" envId={props.envId} project={props.project} meta={meta} />
    },
    {
      title: 'Closed date',
      key: 'closedDate',
      sorter: closedSorter,
      width: 190,
      render: (meta: DeploymentPlanMeta) => <ClosedDeploymentColumnItem property="closedDate" envId={props.envId} project={props.project} meta={meta} />
    },
    {
      title: 'Installations',
      key: 'installations',
      align: 'center',
      width: 120,
      render: (meta: DeploymentPlanMeta) => <ClosedDeploymentColumnItem property="installations" envId={props.envId} project={props.project} meta={meta} />
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      width: 100,
      render: (meta: DeploymentPlanMeta) => <ClosedDeploymentColumnItem property="notes" envId={props.envId} project={props.project} meta={meta} />
    }
  ];

  return (
    <>
      {!deploymentPlans.isSuccess && <Skeleton active />}
      {deploymentPlans.isSuccess && (
        <>
          {lockedPlans.length > 0 ? (
            <>
              <Table
                size="small"
                rowKey={getRowKey}
                columns={columns}
                scroll={{ x: '100%' }}
                dataSource={lockedPlans}
                sticky={{
                  offsetHeader: 0
                }}
                pagination={{
                  showSizeChanger: true,
                  current: tablePageNumber,
                  onChange: (n) => setTablePage(n.toString()),
                  defaultPageSize: 50,
                  pageSize: pageSizeNumber,
                  onShowSizeChange: (_, n) => setPageSize(n.toString()),
                  pageSizeOptions: ['20', '50', '100']
                }}
              />
            </>
          ) : (
            <Empty description="Go to 'Apps' to create the first deployment plan" />
          )}
        </>
      )}
    </>
  );
};
