import { Alert, Row, Col, Space, Typography, Flex } from 'antd';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { SoftwareItemSelection } from '../../../domain';
import { useBundles } from '../../bundles/hooks/useBundles';
import { useProject } from '../../projects/hooks/useProject';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { formatDateTime } from '../../shared/components/formatDate';
import { MarkdownPreview } from '../../shared/components/MarkdownPreview';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { BundleConfiguration, BundleRelease, BundleReleaseLite } from '../../../api/engineering/domain/types';
import ProjectContentWrapper from '../../projects/components/ProjectContentWrapper';
import { Link, useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { SoftwareList } from './components/SoftwareList/SoftwareList';
import { SoftwareCompareList } from './components/SoftwareList/SoftwareCompareList';
import { AddEditConfigurationVersion } from './components/ConfigurationActions/AddEditConfigurationVersion/AddEditConfigurationVersion';
import { ScopedSoftwareApp, scopeToCommon, scopeToProject } from './types';
import { CreateDeploymentPlan } from './components/ConfigurationActions/CreateDeploymentPlan';
import SwitchToCompareViewButton from './components/ConfigurationActions/SwitchToCompareViewButton';
import { AddSoftwareApp } from './components/ConfigurationActions/AddSoftwareApp/AddSoftwareApp';
import useConfigurationVersion from './hooks/useConfigurationVersion';
import useDeleteConfigurationVersion from './hooks/useDeleteConfigurationVersion';
import RevertChangesButton from './components/ConfigurationActions/RevertChangesButton';
import { ConfigurationVersionSelection } from './components/ConfigurationActions/ConfigurationVersionSelection/ConfigurationVersionSelection';
import GenerateReportButton from './components/ConfigurationActions/GenerateReportButton';
import ScopeSelection from './components/ConfigurationActions/ScopeSelection';
import useConfigurations from '../Configurations/hooks/useConfigurations';
import { useInAppNavigate } from '../../navigation/hooks';
import { Redirects } from '@pacts/utils-redirects';
import { ScrollDialog } from '../../layout/components/ScrollDialog';

import styled from 'styled-components';
import useConfigurationVersions from '../Configurations/hooks/useConfigurationVersions';
import { WarningOutlined } from '@ant-design/icons';
import { SoftwareAppVersion, ToolVersion } from '@pacts/engineeringservice-api';
import { softwareItemHash } from './utils/softwareItemHash';

const { Text } = Typography;

const initialBundleSelection: BundleConfigurationSelection = {
  bundleId: '',
  projectId: '',
  bundleConfigurationId: '',
  bundleConfigurationVersionId: '',
  bundleConfigurationName: '',
  bundleConfigurationVersionName: ''
};

const OuterSpace = styled(Space)`
  float: right !important;
`;

const StyledBottomText = styled(Typography.Text)`
  padding: 20px 0 80px;
`;

const StyledWarningIcon = styled(WarningOutlined)`
  margin-bottom: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.colorError};
`;

const hashSelection = (input: SoftwareItemSelection[]): string => {
  return (
    input
      // include id in hash since id changes might also result in a selection update
      .map(
        (softwareItemSelection) =>
          softwareItemHash(softwareItemSelection.softwareItem) +
          ((softwareItemSelection.softwareItem as ScopedSoftwareApp).idSoftwareApp
            ? `-${(softwareItemSelection.version as SoftwareAppVersion).idSoftwareAppVersion}`
            : `-${(softwareItemSelection.version as ToolVersion).idToolVersion}`)
      )
      .sort()
      .join('+')
  );
};

export const ConfigurationDetails = () => {
  const params = useParams();
  const navigate = useInAppNavigate();
  const [selectedBundleConfigPrimary, setSelectedBundleConfigPrimary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const [selectedBundleConfigSecondary, setSelectedBundleConfigSecondary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const projectId = useMemo(() => {
    setSelectedBundleConfigPrimary({ ...initialBundleSelection, bundleConfigurationId: String(params?.configurationId) });
    setSelectedBundleConfigSecondary({ ...initialBundleSelection, bundleConfigurationId: String(params?.configurationId) });
    return params?.projectId || '';
  }, [params?.projectId, setSelectedBundleConfigPrimary, setSelectedBundleConfigSecondary, params?.configurationId]);
  const permissions = usePermissions({ projectId: projectId.toString() });
  const emptyArray = useRef([]);
  const [selectedSoftwareItems, setSelectedApps] = useState<SoftwareItemSelection[]>();
  const [initialSelection, setInitialSelection] = useState([] as SoftwareItemSelection[]);
  const [comparisonViewSearchParam, setComparisonViewSearchParam] = useSearchParameter('mode');
  const [isDirty, setIsDirty] = useState(false);
  const [showSearchParameter] = useSearchParameter('show');
  const [projectType] = useSearchParameter('type');
  const isNoSelectedApps = (selectedSoftwareItems?.length || 0) < 1;
  const comparisonView = comparisonViewSearchParam === 'compare';
  const setComparisonView = (enabled: boolean) => setComparisonViewSearchParam(enabled ? 'compare' : 'control');
  const [noEnvsModalOpen, setNoEnvsModalOpen] = useState(false);

  // Object used for mapping the URL with a bundle name and determine the current bundle
  const bundleNames = {
    apps: 'Applications',
    tools: 'Engineering Tools',
    'project-software': 'Project Software'
  };

  type BundleNameType = keyof typeof bundleNames;

  const project = useProject(projectId);
  const bundles = useBundles(projectId);
  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes(bundleNames[params?.bundle as BundleNameType]));
  const bundleId = usedBundle?.idBundle?.toString() || '';
  const deleteBundleConfigVersion = useDeleteConfigurationVersion();
  const bundleConfigurations = useConfigurations(projectId, bundleId);
  const configName = (bundleConfigurations.data || []).find((config) => config.idBundleConfiguration === Number(params.configurationId))?.name;
  const showBundleItemsOnly = !showSearchParameter || showSearchParameter === 'bundle';
  const bundleConfigVersions = useConfigurationVersions(projectId, bundleId, selectedBundleConfigPrimary.bundleConfigurationId);
  const isOldBundle = params?.bundle !== 'project-software';

  const projectSoftwareBundle = bundles.data?.find((bundle) => bundle.name.includes('Project Software'));
  const projectSoftwareBundleId = projectSoftwareBundle?.idBundle?.toString() || '';
  const projectSoftwareBundleConfigurations = useConfigurations(projectId, projectSoftwareBundleId).data;
  const equivalentProjectSoftwareConfiguration = projectSoftwareBundleConfigurations?.find(
    (configuration: BundleConfiguration) => configuration.name === configName
  );

  const bundleReleaseA = useConfigurationVersion(
    projectId,
    bundleId,
    selectedBundleConfigPrimary.bundleConfigurationId,
    selectedBundleConfigPrimary.bundleConfigurationVersionId
  );
  const bundleReleaseB = useConfigurationVersion(
    projectId,
    bundleId,
    selectedBundleConfigSecondary.bundleConfigurationId,
    selectedBundleConfigSecondary.bundleConfigurationVersionId
  );

  const breadcrumbItems = [
    { title: project.data?.name, onClick: () => navigate(`/projects?active=${projectId}&type=${projectType}`) },
    { title: 'Project software', onClick: () => navigate(`/projects/${projectId}/project-software/configurations?active=${projectId}&type=${projectType}`) },
    { title: configName, onClick: comparisonView ? () => setComparisonView(false) : undefined },
    ...(comparisonView
      ? [
          {
            title: 'Compare releases'
          }
        ]
      : [])
  ];

  const extractSelectionFromBundleRelease = useMemo(
    () => (data: BundleRelease) => {
      if (!data) {
        return emptyArray.current;
      }
      const matchedProjectApps = (data.projectSoftwareApps || []).map(scopeToProject).map((et) => {
        return { softwareItem: et, version: et.latestVersion!, target: et.latestVersion!.targets[0] };
      });

      const matchedCommonApps = (data.softwareApps || []).map(scopeToCommon).map((et) => {
        return { softwareItem: et, version: et.latestVersion!, target: et.latestVersion!.targets[0] };
      });

      const matchedTools = (data.engineeringTools || []).map((et) => {
        return { softwareItem: et, version: et.latestVersion! };
      });

      return [...matchedProjectApps, ...matchedCommonApps, ...matchedTools];
    },
    []
  );

  const extractedItemsLeft = useMemo(() => {
    if (!bundleReleaseB.isSuccess) {
      return undefined;
    }
    return extractSelectionFromBundleRelease(bundleReleaseB.data);
  }, [bundleReleaseB.isSuccess, bundleReleaseB.data, extractSelectionFromBundleRelease]);

  const setSelectionToBundleContent = useCallback(
    (force?: boolean) => {
      // when bundle release data is updated
      // set empty if no data is available
      if (!bundleReleaseA.data) {
        setInitialSelection([]);
        setSelectedApps([]);
        return;
      }

      // if data is available calculate the content of the bundle
      // update the selection only if the bundle content changed
      // e.g., in case a different version is selected
      const releaseSelection = extractSelectionFromBundleRelease(bundleReleaseA.data);
      const initialSelectionHash = hashSelection(initialSelection ?? []);
      const releaseHash = hashSelection(releaseSelection);

      // don't update the selection if the bundle content is equal
      if (initialSelectionHash === releaseHash && !force) return;

      // if bundle contents are not equal a different bundle has been selected
      // or the content of the current one has changed - update selection
      setInitialSelection(releaseSelection);
      setSelectedApps(releaseSelection);

      // only update callback bundleRelease data changes
    },
    // eslint-disable-next-line
    [bundleReleaseA.data]
  );

  const handleBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    // force-update the selection to the current bundle content
    // only if the selected bundle release id has changed
    if (val.bundleConfigurationVersionId !== selectedBundleConfigPrimary.bundleConfigurationId) {
      setSelectionToBundleContent(true);
    }
    setSelectedBundleConfigPrimary(val);
  };

  const handleSecondaryBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    setSelectedBundleConfigSecondary(val);
  };

  // when updateSelection callback is updated
  // trigger selection update
  useEffect(() => {
    setSelectionToBundleContent();
  }, [setSelectionToBundleContent]);

  const configVersionDropdownExtensions =
    (!comparisonView && [
      <RevertChangesButton disabled={!isDirty} onClick={() => setSelectedApps(initialSelection)} />,
      <ExpandableMenu id={`bundle-config-version-actions-${projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        {permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
          <AddEditConfigurationVersion
            projectId={projectId.toString()}
            bundleId={bundleId}
            disabled={isNoSelectedApps}
            bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
            selectedSoftwareItems={selectedSoftwareItems as SoftwareItemSelection[]}
            onAddBundleConfigurationVersion={(bcv) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
              });
            }}
          />
        )}
        {permissions.engineeringSvc$putProjectBundleConfigurationReleasesNotes && (
          <AddEditConfigurationVersion
            isEdit
            projectId={projectId.toString()}
            bundleId={bundleId}
            version={bundleReleaseA.data}
            bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
            selectedSoftwareItems={selectedSoftwareItems as SoftwareItemSelection[]}
            onAddBundleConfigurationVersion={(bcv) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfigurationRelease && (
          <ConfirmationButton
            caption="Delete Version"
            danger
            onOk={() =>
              deleteBundleConfigVersion.mutate([
                projectId.toString(),
                bundleId,
                selectedBundleConfigPrimary.bundleConfigurationId,
                selectedBundleConfigPrimary.bundleConfigurationVersionId
              ])
            }
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const hasBundles = !bundleConfigVersions.isLoading && bundleConfigVersions.data?.length && bundleConfigVersions.data.length > 0;

  const bundleActions = (
    <>
      <ExpandableMenu id={`bundle-config-version-extra-actions-${projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
        {hasBundles && <SwitchToCompareViewButton state={comparisonView} onChange={() => setComparisonView(!comparisonView)} />}
        {!comparisonView && permissions.engineeringSvc$addProjectSoftwareApp && <AddSoftwareApp projectId={projectId.toString()} />}
        {!comparisonView &&
          hasBundles &&
          permissions.engineeringSvc$getProjectBundleConfigurationReleaseReport &&
          project.data &&
          usedBundle &&
          selectedBundleConfigPrimary && (
            <GenerateReportButton project={project.data} bundle={usedBundle} bundleConfig={selectedBundleConfigPrimary} isDirty={isDirty} />
          )}
        {!comparisonView && hasBundles && permissions.deploymentSvc$addDeploymentPlan && !isOldBundle && (
          <CreateDeploymentPlan
            projectId={parseInt(projectId)}
            bundleId={parseInt(bundleId)}
            disabled={isDirty || !selectedSoftwareItems}
            bundleConfigId={parseInt(selectedBundleConfigPrimary.bundleConfigurationId)}
            bundleConfigVersionId={parseInt(selectedBundleConfigPrimary.bundleConfigurationVersionId)}
            selection={selectedSoftwareItems ?? []}
            noEnvsCallback={() => setNoEnvsModalOpen(true)}
          />
        )}
      </ExpandableMenu>
    </>
  );

  const aIsEmpty = !selectedBundleConfigPrimary.bundleConfigurationVersionId && selectedBundleConfigPrimary.bundleId;
  const bIsEmpty = !selectedBundleConfigSecondary.bundleConfigurationVersionId && selectedBundleConfigSecondary.bundleId;
  const aLoading = (bundleReleaseA.isLoading || bundleReleaseA.data === undefined) && !aIsEmpty;
  const bLoading = comparisonView && (bundleReleaseB.isLoading || bundleReleaseB.data === undefined) && !bIsEmpty;
  const loading = aLoading || bLoading;

  // TODO: extract validation for ids and use in hooks as well
  if (parseInt(projectId, 10) < 0) {
    return <Alert message="Invalid Project Id" type="error" />;
  }

  const redirectToEnvironments = () => {
    navigate(`/projects/${projectId}/deployments/environments?referrer=deployments`);
  };

  const handleAddBundleConfigurationVersion = (configurationVersion: BundleReleaseLite) => {
    setIsDirty(false);
    setSelectedBundleConfigPrimary({
      ...selectedBundleConfigPrimary,
      bundleConfigurationVersionId: configurationVersion.idBundleRelease?.toString() || ''
    });
  };

  return (
    <ProjectContentWrapper isLoading={project.isLoading} breadcrumbItems={breadcrumbItems}>
      {isOldBundle && (
        <Row>
          <Col flex="100%">
            <Alert
              message={
                <span>
                  This is an old release link which is available in read-only mode.{' '}
                  {equivalentProjectSoftwareConfiguration && (
                    <span>
                      Click{' '}
                      <Link
                        to={`/projects/${projectId}/project-software/configurations/${equivalentProjectSoftwareConfiguration?.idBundleConfiguration}?show=bundle`}
                      >
                        here
                      </Link>{' '}
                      to navigate to the new Project Software page where this release has been migrated.
                    </span>
                  )}
                </span>
              }
              type="warning"
              showIcon
            />
            <br />
          </Col>
        </Row>
      )}
      <Row justify="start">
        <Col flex="50%">
          {!!configName && (
            <Title level={4} style={{ margin: 0, marginBottom: 4 }}>
              {configName} Release
            </Title>
          )}
          {!comparisonView && bundleReleaseA.data && (
            <>
              <Text type="secondary">Release Notes:</Text>
              <MarkdownPreview content={bundleReleaseA.data.releaseNotes || ''} title="Release note" />
              <br />
              <Text type="secondary">
                {bundleReleaseA.data.createdAt ? formatDateTime(new Date(bundleReleaseA.data.createdAt)) : ''}
                {bundleReleaseA.data.createdBy ? ` by ${bundleReleaseA.data.createdBy}` : ''}
              </Text>
            </>
          )}
        </Col>
        <Col flex="auto">
          <OuterSpace direction="vertical" size={0}>
            <OuterSpace>
              {comparisonView && (
                <ConfigurationVersionSelection
                  projectId={projectId.toString()}
                  bundleId={bundleId}
                  selected={selectedBundleConfigSecondary}
                  onSelect={handleSecondaryBundleConfigVersionChange}
                  id="l"
                />
              )}
              <ConfigurationVersionSelection
                projectId={projectId.toString()}
                bundleId={bundleId}
                selected={selectedBundleConfigPrimary}
                onSelect={handleBundleConfigVersionChange}
                disableLabel={comparisonView}
                isDirty={isDirty}
                configurationVersionDropdownExtensions={!isOldBundle ? configVersionDropdownExtensions : undefined}
                id="r"
              />
            </OuterSpace>
            <OuterSpace>
              {!comparisonView && selectedBundleConfigPrimary && !loading && <ScopeSelection showAll={!hasBundles} extension={[bundleActions]} />}
              {comparisonView && bundleActions}
            </OuterSpace>
          </OuterSpace>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        {!bundleConfigVersions.isLoading && !bundleConfigVersions.data?.length && (
          <Alert
            style={{ marginBottom: '20px', width: '100%', zIndex: 5 }}
            message={
              <Flex justify="space-between" align="center">
                <Typography.Text>
                  <Typography.Text strong>Create your first release</Typography.Text>. Please select the software needed for the release before creating it.{' '}
                  <Typography.Text>
                    See{' '}
                    <Typography.Link href={Redirects.WIKI_PACTS_CREATE_PROJECT_RELEASES} target="_blank">
                      how to create releases
                    </Typography.Link>
                  </Typography.Text>
                  .
                </Typography.Text>
                {!bundleConfigVersions.isLoading && !bundleConfigVersions.data?.length && permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
                  <AddEditConfigurationVersion
                    isButton
                    projectId={projectId.toString()}
                    bundleId={bundleId}
                    disabled={isNoSelectedApps}
                    bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
                    selectedSoftwareItems={selectedSoftwareItems as SoftwareItemSelection[]}
                    onAddBundleConfigurationVersion={handleAddBundleConfigurationVersion}
                  />
                )}
              </Flex>
            }
            type="warning"
            showIcon
          />
        )}
        <Col span={24}>
          {!comparisonView && project.data && (
            <SoftwareList
              onDirty={setIsDirty}
              project={project.data}
              selected={selectedSoftwareItems}
              initiallySelected={initialSelection}
              onSelect={(selected) => setSelectedApps([...selected])}
              showBundleItemsOnly={showBundleItemsOnly}
              loading={loading}
              readonly={isOldBundle}
            />
          )}
          {comparisonView && (
            <SoftwareCompareList
              project={project.data}
              selectedTitleLeft={`${selectedBundleConfigSecondary.bundleConfigurationName}: ${selectedBundleConfigSecondary.bundleConfigurationVersionName}`}
              selectedTitleRight={`${selectedBundleConfigPrimary.bundleConfigurationName}: ${selectedBundleConfigPrimary.bundleConfigurationVersionName}`}
              projectId={projectId.toString()}
              selectedItemsRight={selectedSoftwareItems}
              selectedItemsLeft={extractedItemsLeft}
              loading={loading}
            />
          )}
        </Col>
      </Row>
      <ScrollDialog
        title="Set up environment before deployment"
        okText="Create new environment"
        width={800}
        open={noEnvsModalOpen}
        onOk={redirectToEnvironments}
        onCancel={() => setNoEnvsModalOpen(false)}
      >
        <>
          <Row justify="center">
            <StyledWarningIcon />
          </Row>
          <Row justify="center">
            <Typography.Text>
              To create a deployment, you'll first need to create an <Typography.Text strong>environment</Typography.Text> with OTCs and Devices.
            </Typography.Text>
          </Row>
          <Row justify="center">
            <StyledBottomText>Let's get everything in place so you can create the deployment plan!</StyledBottomText>
            <Typography.Text></Typography.Text>
          </Row>
        </>
      </ScrollDialog>
    </ProjectContentWrapper>
  );
};
