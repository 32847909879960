import { useQuery } from '@tanstack/react-query';
import { usePermissions } from '../../../../../session/hooks/usePermissions';
import { useEngineeringBackend } from '../../../../../../api/engineering/hooks/useEngineeringBackend';
import { SoftwareAppVersion } from '../../../../../../api';

export function useProjectSoftwareAppVersionVulnerabilities(projectId: number, appId: number, version: SoftwareAppVersion) {
  const hasComponentId = version.targets.some((t) => !!t.icsPortalComponentId);

  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({
    projectId: projectId.toString(),
    softwareAppId: appId.toString(),
    idSoftwareAppVersion: version.idSoftwareAppVersion.toString()
  });

  return useQuery(
    ['projectSoftwareAppVersionVulnerabilities', projectId.toString(), appId.toString(), version.idSoftwareAppVersion.toString()],
    () => {
      return backend.queryProjectAppVersionVulnerabilities(projectId, appId, version.idSoftwareAppVersion);
    },
    {
      enabled: hasComponentId && permissions.engineeringSvc$getProjectSoftwareAppVersionVulnerabilities
    }
  );
}
