import { useState } from 'react';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { usePermissions } from '../../../session/hooks/usePermissions';
import { SemanticVersion } from '../../../../domain/versioning/semanticVersion';
import { useCreateCommonSoftwareApp } from './hooks/useCreateCommonSoftwareApp';
import { SoftwareAppCreate } from '../../../../api/engineering/domain/types';
import { ShiftedDrawer } from '../../../shared/components/ShiftedDrawer';
import { cloneDeep } from 'lodash';
import { AddSoftwareAppForm } from './AddSoftwareAppForm';

const initialSwApp: SoftwareAppCreate = {
  name: '',
  categories: [],
  description: '',
  installationOptions: '',
  latestVersion: {
    version: SemanticVersion.initial().toString(),
    targets: [],
    releaseNotes: ''
  },
  documentationLink: ''
};

export const AddCommonSoftwareApp = () => {
  const permissions = usePermissions();
  const [modalVisible, setModalVisible] = useState(false);

  const createSoftwareApp = useCreateCommonSoftwareApp();
  const [form] = Form.useForm();

  let okHandler: () => any;
  let resetHandler: () => any;

  return (
    <>
      {permissions.engineeringSvc$addCommonSoftwareApp && (
        <Button
          icon={<PlusOutlined />}
          loading={createSoftwareApp.isLoading}
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Add Software App
        </Button>
      )}

      <ShiftedDrawer
        title="Add Software App"
        open={modalVisible}
        loading={createSoftwareApp.isLoading}
        destroyOnClose
        isFormDrawer
        onOk={() => {
          okHandler();
        }}
        onCancel={() => {
          resetHandler();
          setModalVisible(false);
        }}
        afterOpenChange={(open) => {
          if (!open) form.resetFields();
        }}
      >
        <AddSoftwareAppForm
          initial={cloneDeep(initialSwApp)}
          ok={(ok) => {
            okHandler = ok;
          }}
          reset={(cancel) => {
            resetHandler = cancel;
          }}
          form={form}
          onFinish={async (ps) => {
            return new Promise((resolve, reject) => {
              createSoftwareApp
                .mutateAsync([ps])
                .then(() => {
                  setModalVisible(false);
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            });
          }}
        />
      </ShiftedDrawer>
    </>
  );
};
