import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SoftwareApp, SoftwareAppUpdate } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../api/shared/successPresenter';

export function useUpdateProjectSoftwareApp() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<SoftwareApp, any, [string, SoftwareAppUpdate]>(async ([projectId, softwareApp]) => {
    const updatedSoftwareApp = await backend.updateProjectApp(projectId, softwareApp);

    queryClient.invalidateQueries(['softwareApps', projectId]);
    queryClient.invalidateQueries(['softwareApp', projectId, updatedSoftwareApp.idSoftwareApp?.toString()]);

    presentSuccess(`Successfully updated Software App ${softwareApp.name}`);

    return updatedSoftwareApp;
  });
}
