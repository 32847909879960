import { useCallback, useEffect, useMemo, useState } from 'react';
import { Skeleton } from 'antd';
import { SoftwareCompareVersionResult, softwareAppVersionComparisonResultValue } from './components/SoftwareCompareVersionResult/SoftwareCompareVersionResult';
import { SoftwareItemSelection } from '../../../../../domain';
import { useGetProjectSoftwareAppsScoped } from './hooks/useProjectSoftwareApps';
import { ComparisonResult } from '../../../../comparison';
import { useCommonSoftwareAppsScoped } from './hooks/useCommonSoftwareApps';
import { ScopedSoftwareApp } from '../../types';
import { Comparator } from '../../../../../domain/extensions/comparison';
import { Project, SoftwareApp, SoftwareAppVersion } from '../../../../../api/engineering/domain/types';
import Table from '../../../../shared/components/Table/Table';
import { SegmentedValue } from 'antd/es/segmented';
import Segmented from '../../../../shared/components/Segmented/Segmented';
import { ColumnsType } from 'antd/es/table';
import * as Styled from './components/SoftwareTable/SoftwareTable.styled';
import { MetricOnVisible } from '../../../../metrics/components/MetricOnVisible';
import { MetricViewIds } from '../../../../metrics/constants';
import { projectToTags } from '../../../../metrics/utils/mapping';
import { useSearchParameter } from '../../../../navigation/hooks/useSearchParameter';
import { MarkdownPreview } from '../../../../shared/components/MarkdownPreview';
import { useLocalStorageState, useDebounce } from '../../../../shared/hooks';
import styled from 'styled-components';
import { ToolVersion, Tool } from '../../../../../api/engineering/domain/types';
import { useTools } from './hooks/useTools';

export const StyledTable = styled(Table)`
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
    max-height: calc(100vh - 460px) !important;
  }
` as typeof Table;

export type ProjectSoftwareListCompareProps = {
  project?: Project;
  projectId: string;
  selectedItemsRight?: SoftwareItemSelection[];
  selectedItemsLeft?: SoftwareItemSelection[];
  selectedTitleRight: string;
  selectedTitleLeft: string;
  loading?: boolean;
};

const prefferedCategoryListKey = 'preferred-app-category-list-key';
const allCategoriesLabel = 'All Categories';

const findSoftwareItem = (softwareItem: ScopedSoftwareApp | Tool, selection?: SoftwareItemSelection[]) => {
  return selection?.find((softwareItemSelection) =>
    (softwareItem as ScopedSoftwareApp).idSoftwareApp
      ? (softwareItem as ScopedSoftwareApp).idSoftwareApp === (softwareItemSelection.softwareItem as ScopedSoftwareApp).idSoftwareApp &&
        (softwareItem as ScopedSoftwareApp).scope === (softwareItemSelection.softwareItem as ScopedSoftwareApp).scope
      : (softwareItem as Tool).id === (softwareItemSelection.softwareItem as Tool).id
  );
};

export const SoftwareCompareList = (props: ProjectSoftwareListCompareProps) => {
  const softwareApps = useGetProjectSoftwareAppsScoped(props.projectId);
  const [prefferedTabKey, setPrefferedTabKey] = useLocalStorageState<string>(prefferedCategoryListKey, undefined);
  const [activeTabKey, setActiveTabKey] = useSearchParameter('tab', prefferedTabKey || undefined);
  const commonSoftwareApps = useCommonSoftwareAppsScoped();
  const tools = useTools();
  const [filteredApps, setFilteredApps] = useState({} as any);

  const isLoading =
    softwareApps.isLoading ||
    commonSoftwareApps.isLoading ||
    tools.isLoading ||
    props.loading ||
    filteredApps[activeTabKey ?? ''] == null ||
    !props.selectedItemsLeft ||
    !props.selectedItemsRight;

  // make the component more resilient to props not being set in order
  // when the left or right items lists are set after loading=false, the default sort order does not work
  // hence debounce the loading by a few cycles to make sure the table is rendered with correct data
  // enable only for transition from true to false
  const loadingDebounced = useDebounce(isLoading, 20, true) || isLoading;
  // debounce selected items to compensate for changes caused by hooks
  // make sure debounced time is lower than loading
  const leftDebounced = useDebounce(props.selectedItemsLeft, 10);
  const rightDebounced = useDebounce(props.selectedItemsRight, 10);

  const getCategoryName = (softwareItem: SoftwareApp | Tool): string => {
    return (softwareItem as Tool).category
      ? 'Tools'
      : (softwareItem as ScopedSoftwareApp).categories && (softwareItem as ScopedSoftwareApp).categories.length > 0
        ? (softwareItem as ScopedSoftwareApp).categories[0].name
        : 'unknown';
  };

  const handleActiveTabChange = (key: SegmentedValue) => {
    const keyStr = key.toString();

    setActiveTabKey(keyStr);
    setPrefferedTabKey(keyStr);
  };
  const statusSorter = useCallback(
    (a: ScopedSoftwareApp | Tool, b: ScopedSoftwareApp | Tool) => {
      const aaSoftwareItem = findSoftwareItem(a, rightDebounced);
      const abSoftwareItem = findSoftwareItem(a, leftDebounced);
      const aComparisonResult = softwareAppVersionComparisonResultValue(
        aaSoftwareItem?.version as SoftwareAppVersion | ToolVersion,
        abSoftwareItem?.version as SoftwareAppVersion | ToolVersion
      );

      const baSoftwareItem = findSoftwareItem(b, rightDebounced);
      const bbSoftwareItem = findSoftwareItem(b, leftDebounced);
      const bComparisonResult = softwareAppVersionComparisonResultValue(
        baSoftwareItem?.version as SoftwareAppVersion | ToolVersion,
        bbSoftwareItem?.version as SoftwareAppVersion | ToolVersion
      );

      return aComparisonResult - bComparisonResult;
    },
    [rightDebounced, leftDebounced]
  );

  const filterApps = useCallback(() => {
    const mergedApps = [...softwareApps.data, ...commonSoftwareApps.data, ...(tools.data || [])];
    const tabsCategories = mergedApps
      ?.filter((softwareItem) => findSoftwareItem(softwareItem, props.selectedItemsRight) || findSoftwareItem(softwareItem, props.selectedItemsLeft))
      .reduce((rv, x) => {
        const category = getCategoryName(x);
        (rv[allCategoriesLabel] = rv[allCategoriesLabel] || []).push(x);
        (rv[category] = rv[category] || []).push(x);
        return rv;
      }, {} as any);

    Object.keys(tabsCategories).forEach((key) => {
      tabsCategories[key].sort(statusSorter).reverse();
    });

    const groupedSoftwareApps = {
      ...tabsCategories
    };

    return groupedSoftwareApps;
  }, [props.selectedItemsLeft, props.selectedItemsRight, softwareApps.data, commonSoftwareApps.data, tools.data, statusSorter]);

  useEffect(() => {
    setFilteredApps(filterApps());
  }, [filterApps]);

  useEffect(() => {
    if (filteredApps[activeTabKey ?? ''] == null && Object.keys(filteredApps).length > 1) {
      const defaultKey = Object.keys(filteredApps)[0];

      setActiveTabKey(defaultKey);
    }
  }, [filteredApps, activeTabKey, setActiveTabKey]);

  const columns: ColumnsType<ScopedSoftwareApp | Tool> = useMemo(
    () => [
      {
        title: 'Software',
        key: 'name',
        width: '300px',
        sorter: (a: ScopedSoftwareApp | Tool, b: ScopedSoftwareApp | Tool) => Comparator.lexicographicalComparison(a.name, b.name),
        render: (softwareItem: ScopedSoftwareApp | Tool) => {
          return softwareItem.name;
        }
      },
      {
        title: 'Description',
        key: 'description',
        render: (softwareItem: ScopedSoftwareApp | Tool) => <MarkdownPreview title="Description" content={softwareItem.description || ''} />,
        width: 400
      },
      {
        title: 'Status',
        key: 'status',
        filters: [
          {
            text: 'Hide Equal Items',
            value: ComparisonResult.EQUAL
          }
        ],
        onFilter: (value: any, record: ScopedSoftwareApp | Tool) => {
          const aSoftwareItem = findSoftwareItem(record, rightDebounced);
          const bSoftwareItem = findSoftwareItem(record, leftDebounced);
          if (
            (aSoftwareItem?.version as SoftwareAppVersion).idSoftwareAppVersion
              ? (aSoftwareItem?.version as SoftwareAppVersion).idSoftwareAppVersion === (bSoftwareItem?.version as SoftwareAppVersion).idSoftwareAppVersion
              : (aSoftwareItem?.version as ToolVersion).idToolVersion === (bSoftwareItem?.version as ToolVersion).idToolVersion
          ) {
            return false;
          }
          return true;
        },
        sortDirections: ['descend'],
        sorter: statusSorter,
        render: (softwareItem: ScopedSoftwareApp | Tool) => {
          const aSoftwareItem = findSoftwareItem(softwareItem, rightDebounced);
          const bSoftwareItem = findSoftwareItem(softwareItem, leftDebounced);
          return (
            <SoftwareCompareVersionResult
              a={aSoftwareItem?.version as SoftwareAppVersion | ToolVersion}
              b={bSoftwareItem?.version as SoftwareAppVersion | ToolVersion}
            />
          );
        },
        width: 200
      },
      {
        title: props.selectedTitleLeft,
        key: 'versionA',
        render: (softwareItem: ScopedSoftwareApp | Tool) => {
          const bSoftwareItem = findSoftwareItem(softwareItem, leftDebounced);
          return bSoftwareItem?.version?.version || '--';
        },
        width: 200
      },
      {
        title: props.selectedTitleRight,
        key: 'versionb',
        render: (softwareItem: ScopedSoftwareApp | Tool) => {
          const aSoftwareItem = findSoftwareItem(softwareItem, rightDebounced);
          return aSoftwareItem?.version?.version || '--';
        },
        width: 200
      }
    ],
    [props.selectedTitleLeft, props.selectedTitleRight, leftDebounced, rightDebounced, statusSorter]
  );

  if (activeTabKey == null) {
    return null;
  }

  if (loadingDebounced) {
    return <Skeleton active />;
  }

  const tabOptions = Object.keys(filteredApps);
  const sortedTabOptions = [tabOptions[0], ...tabOptions.slice(1).sort((a, b) => Comparator.lexicographicalComparison(a, b))];

  const hasSegmented = tabOptions.length > 2;
  const segmentedHeight = hasSegmented ? 54 : 0;

  return (
    <div>
      <MetricOnVisible view={MetricViewIds.appsCompareList} payload={props.project ? projectToTags(props.project) : undefined} />
      {hasSegmented ? (
        <Styled.SegmentedWrapper>
          <Segmented value={activeTabKey} options={sortedTabOptions} onChange={handleActiveTabChange} />
        </Styled.SegmentedWrapper>
      ) : null}
      <StyledTable
        scroll={{ x: true }}
        sticky={{
          offsetHeader: segmentedHeight
        }}
        columns={columns}
        rowKey={(record: ScopedSoftwareApp | Tool) =>
          ((record as ScopedSoftwareApp).idSoftwareApp ? (record as ScopedSoftwareApp).idSoftwareApp?.toString() : (record as Tool).id.toString()) || ''
        }
        dataSource={filteredApps[activeTabKey] ?? []}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 20,
          pageSizeOptions: ['10', '20', '50']
        }}
      />
    </div>
  );
};
