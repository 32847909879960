import { useMemo } from 'react';
import { Project, SoftwareComponent, SoftwareAppVersion, ToolVersion, SoftwareApp, Tool } from '../../../api';
import { useComponentVersion } from '../hooks/useComponentVersion';
import { ToolVersionVulnerabilityButton } from '../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersionVulnerabilityButton/ToolVersionVulnerabilityButton';
import { ProjectSoftwareAppVersionVulnerabilityButton } from '../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersionVulnerabilityButton/ProjectSoftwareAppVersionVulnerabilityButton';
import { CommonSoftwareAppVersionVulnerabilityButton } from '../../ProjectSoftware/ConfigurationDetails/components/SoftwareItemVersionVulnerabilityButton/CommonSoftwareAppVersionVulnerabilityButton';

/**
 * Used inside the Deployment version name column of version list tables.
 * It checks if an ICS Portal ID exists for the version.
 * If yes, it displays an extra button which can show version
 * vulnerability information.
 */
export const ComponentVulnerabilityButton = (props: { project: Project; component: SoftwareComponent }) => {
  const { project, component } = props;

  // Convert component to Tool / SoftwareApp.
  const { query, hasPermission } = useComponentVersion(project.idProject, component, true);

  const isApp = component.type === 'app';
  const isProject = component.scope === 'project';

  // Check which button we need to display.
  const vulnerabilityButton = useMemo(() => {
    if (!query.data) return null;

    if (hasPermission && isApp && isProject) {
      return (
        <ProjectSoftwareAppVersionVulnerabilityButton
          project={project}
          app={query.data.component as SoftwareApp}
          version={query.data.version as SoftwareAppVersion}
        />
      );
    } else if (hasPermission && isApp) {
      return <CommonSoftwareAppVersionVulnerabilityButton app={query.data.component as SoftwareApp} version={query.data.version as SoftwareAppVersion} />;
    } else if (hasPermission && !isApp) {
      return <ToolVersionVulnerabilityButton app={query.data.component as Tool} version={query.data.version as ToolVersion} />;
    }

    return null;
  }, [query.data, hasPermission, isApp, isProject, project]);

  return vulnerabilityButton;
};
