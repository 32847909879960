import { useQuery } from '@tanstack/react-query';
import { DeploymentPlan, useDeploymentBackend } from '../../../api';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

export const useLatestDeploymentPlanOfTheProject = (projectId: number) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({
    projectId: String(projectId)
  });

  const latestConfigRelease = useQuery<DeploymentPlan, [string, string]>(
    ['latestDeploymentPlan', projectId],
    (): Promise<DeploymentPlan> => {
      return backend.getLatestDeploymentPlanOfTheProject(projectId);
    },
    {
      enabled: projectId > -1 && permissions.deploymentSvc$getLatestDeploymentPlanOfTheProject
    }
  );

  return latestConfigRelease;
};
