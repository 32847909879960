import { useState, useEffect, useRef, useMemo, ComponentProps } from 'react';
import { Col, Row, Select } from 'antd';
import { uniqBy } from 'lodash';

import { LatestTag } from '../../../../shared/components/LatestTag';
import { BundleTag } from '../../../../shared/components/BundleTag';
import { SemanticVersion } from '../../../../../domain/versioning/semanticVersion';
import { SyncingTag } from '../../../../shared/components/SyncingTag';

import { useTool } from './hooks/useTool';

import type { ToolVersion } from '../../../../../api/engineering/domain/types';
import { FullWidthSelect } from '../../../../shared/base/Components.styled';
import { ProjectSoftwareAppVersionVulnerabilityButton } from '../SoftwareItemVersionVulnerabilityButton/ProjectSoftwareAppVersionVulnerabilityButton';
import { ToolVersionVulnerabilityButton } from '../SoftwareItemVersionVulnerabilityButton/ToolVersionVulnerabilityButton';
import { useToolVersions } from '../SoftwareItemVersions/components/SoftwareItemVersionsList/hooks/useToolVersions';

const { Option } = Select;

export interface ToolVersionDropdownProps {
  engineeringToolId: string;
  selectedVersion: ToolVersion;
  initiallySelectedVersion?: ToolVersion;
  onSelected: (version: ToolVersion) => any;
  getVulnerabilityDrawerContainer?: ComponentProps<typeof ProjectSoftwareAppVersionVulnerabilityButton>['getVulnerabilityDrawerContainer'];
  readonly?: boolean;
}

export const ToolVersionDropdown = (props: ToolVersionDropdownProps) => {
  const [selectedVersion, setSelectedVersion] = useState('');

  const engineeringTool = useTool(props.engineeringToolId);
  const engineeringToolVersions = useToolVersions(props.engineeringToolId);

  // include initially selected version even without access to software app versions list
  const data = useMemo(() => {
    return uniqBy([...(engineeringToolVersions.data ?? []), props.initiallySelectedVersion].filter(Boolean), (d) => d?.idToolVersion) as ToolVersion[];
  }, [engineeringToolVersions.data, props.initiallySelectedVersion]);

  const versionLatestFirst = useMemo(() => {
    return data.sort((a, b) => {
      if (a.idToolVersion === engineeringTool.data?.latestVersion.idToolVersion) return -1;
      if (b.idToolVersion === engineeringTool.data?.latestVersion.idToolVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    });
  }, [data, engineeringTool.data]);

  const onChange = (version: string) => {
    if (version !== selectedVersion) {
      setSelectedVersion(version);

      if (version === engineeringTool.data?.latestVersion?.idToolVersion?.toString()) {
        props.onSelected(engineeringTool.data?.latestVersion);
      } else if (versionLatestFirst) {
        const existingSelectedVersion = versionLatestFirst.find((swa) => swa.idToolVersion?.toString() === version);

        if (existingSelectedVersion) props.onSelected(existingSelectedVersion);
      }
    }
  };

  const lastInitial = useRef('');
  useEffect(() => {
    if (props.selectedVersion?.idToolVersion.toString() !== lastInitial.current) {
      lastInitial.current = props.selectedVersion?.idToolVersion.toString();
      setSelectedVersion(props.selectedVersion?.idToolVersion.toString());
    }
  }, [props]);

  const hasSelectedVersion = versionLatestFirst.some((s) => s.idToolVersion.toString() === selectedVersion);
  const isLoading = (engineeringToolVersions.isLoading || engineeringTool.isLoading) && data.length < 1;

  const toolVersion = useMemo(
    () => engineeringToolVersions.data?.find((v) => v.idToolVersion.toString() === selectedVersion),
    [engineeringToolVersions.data, selectedVersion]
  );

  const showVulnerabilityButton = engineeringTool.isSuccess && engineeringToolVersions.isSuccess && !!toolVersion;

  return (
    <Row wrap={false} gutter={[8, 0]}>
      <Col flex="auto">
        <FullWidthSelect
          className={`tool-version-selector-${props.engineeringToolId}`}
          value={hasSelectedVersion ? selectedVersion : ''}
          showSearch
          // delete overscrollBehaviour: contain for scroll-chaining
          dropdownStyle={{ overscrollBehavior: 'contain' }}
          optionFilterProp="label"
          filterOption={(input, option) => (option?.title || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
          loading={isLoading}
          onSelect={(val: any) => onChange(val.toString())}
          disabled={props.readonly}
        >
          {versionLatestFirst &&
            versionLatestFirst.map((version) => {
              return (
                <Option
                  id={`common-tool-${props.engineeringToolId}-${version.idToolVersion}`}
                  title={version.version}
                  key={version.idToolVersion?.toString()}
                  value={version.idToolVersion?.toString() || 'unknown'}
                >
                  <span>
                    {version.idToolVersion === engineeringTool.data?.latestVersion?.idToolVersion ? <LatestTag /> : null}
                    {version.idToolVersion === props.initiallySelectedVersion?.idToolVersion ? <BundleTag /> : null}
                    {version.state && version.state !== 'consistent' ? <SyncingTag /> : null}
                    {version.version}
                  </span>
                </Option>
              );
            })}
        </FullWidthSelect>
      </Col>

      <Col flex="40px">
        {showVulnerabilityButton && (
          <ToolVersionVulnerabilityButton
            app={engineeringTool.data}
            version={toolVersion}
            getVulnerabilityDrawerContainer={props.getVulnerabilityDrawerContainer}
          />
        )}
      </Col>
    </Row>
  );
};
