import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tool, ToolUpdate } from '../../../../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../../../../../api/shared/successPresenter';

export function useUpdateTool() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();
  return useMutation<Tool, any, [ToolUpdate]>(async ([tool]) => {
    const updatedTool = await backend.updateTool(tool);

    queryClient.invalidateQueries(['engineeringTools']);
    queryClient.invalidateQueries(['engineeringTool', updatedTool.id?.toString()]);

    presentSuccess(`Successfully updated Engineering Tool ${tool.name}`);

    return updatedTool;
  });
}
