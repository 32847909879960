import { Badge, Descriptions, Space, Typography } from 'antd';
import { usePermissions } from '../../../../session/hooks/usePermissions';
import { useDeleteCommonSoftwareApp } from './hooks/useDeleteCommonSoftwareApp';
import { useDeleteProjectSoftwareApp } from './hooks/useDeleteProjectSoftwareApp';
import { useInAppNavigate } from '../../../../navigation/hooks/useInAppNavigate';
import { formatDateTime } from '../../../../shared/components/formatDate';

import { ConfirmationButton } from '../../../../shared/components/ConfirmationButton';
import { MarkdownRenderer } from '../../../../shared/components/MarkdownRenderer';
import { EditCommonSoftwareApp } from './EditSoftwareItem/EditCommonSoftwareApp';
import { EditProjectSoftwareApp } from './EditSoftwareItem/EditProjectSoftwareApp';
import { ShiftedDrawer } from '../../../../shared/components/ShiftedDrawer';

import type { Project } from '../../../../../api/engineering/domain/types';
import type { ScopedSoftwareApp } from '../../types';
import React from 'react';

type Props = {
  app: ScopedSoftwareApp | null;
  onClose: () => void;
  open: boolean;
  project?: Project;
};

export const SoftwareAppDetails = ({ app, project, open, onClose }: Props) => {
  const permissions = usePermissions({ projectId: project?.idProject.toString() });
  const deleteCommonApp = useDeleteCommonSoftwareApp();
  const deleteProjectApp = useDeleteProjectSoftwareApp();
  const navigate = useInAppNavigate();

  const isProjectScope = app?.scope === 'project';

  return (
    <ShiftedDrawer
      open={open}
      onClose={onClose}
      title={`Details: ${app?.name}`}
      extra={
        <>
          {!!app && (
            <Space>
              {isProjectScope ? (
                <EditProjectSoftwareApp softwareApp={app} projectId={project?.idProject.toString() ?? ''} />
              ) : (
                <EditCommonSoftwareApp softwareApp={app} />
              )}

              {(isProjectScope ? permissions.engineeringSvc$deleteProjectSoftwareApp : permissions.engineeringSvc$deleteCommonSoftwareApp) && (
                <ConfirmationButton
                    title="Deleting software"
                    description="This action cannot be undone."
                    paragraphDescription={
                      <Typography.Paragraph>
                        Are you sure you want to delete <Typography.Text strong>{app.name}</Typography.Text> software?
                      </Typography.Paragraph>
                    }
                  danger
                  buttonType="regular"
                  regularButtonType="default"
                  onOk={() => {
                    if (isProjectScope) {
                      deleteProjectApp
                        .mutateAsync([`${project?.idProject}`, `${app.idSoftwareApp}`])
                        .then(() => navigate(`/projects/${project?.idProject}/apps`, { replace: true }))
                        .catch(() => {});
                    } else {
                      deleteCommonApp
                        .mutateAsync([`${app.idSoftwareApp}`])
                        .then(() =>
                          project?.idProject ? navigate(`/projects/${project?.idProject}/apps`, { replace: true }) : navigate('/apps', { replace: true })
                        )
                        .catch(() => {});
                    }
                  }}
                >
                  Delete
                </ConfirmationButton>
              )}
            </Space>
          )}
        </>
      }
    >
      {!!app && (
        <Descriptions bordered column={1}>
          <Descriptions.Item span={2} label="Description">
            <MarkdownRenderer centered>{`${app.description}`}</MarkdownRenderer>
          </Descriptions.Item>

          <Descriptions.Item span={2} label="Documentation">
            <MarkdownRenderer centered>{`${app.documentationLink}`}</MarkdownRenderer>
          </Descriptions.Item>

          <Descriptions.Item label="Created">
            {app.createdAt ? formatDateTime(new Date(app.createdAt)) : '-'}
            {app.createdBy ? ` by ${app.createdBy}` : ''}
          </Descriptions.Item>

          <Descriptions.Item label="Updated">
            {app.updatedAt ? formatDateTime(new Date(app.updatedAt)) : '-'}
            {app.updatedBy ? ` by ${app?.updatedBy}` : ''}
          </Descriptions.Item>

          <Descriptions.Item label="Category">{app.categories?.map((cat) => cat.name).join('; ')}</Descriptions.Item>

          <Descriptions.Item label={app.latestVersion?.targets?.length === 1 ? 'Target' : 'Targets'}>
            {app.latestVersion?.targets?.map((cat) => cat.target.name).join('; ')}
          </Descriptions.Item>

          <Descriptions.Item label="Application type">
            {isProjectScope ? <Badge status="warning" text="Project specific" /> : <Badge status="error" text="Common / Software Platform Application" />}
          </Descriptions.Item>

          {permissions.all$unrestrictedAdministration && <Descriptions.Item label="Application Id">{`${app.idSoftwareApp}`}</Descriptions.Item>}

          {project?.idProject && permissions.all$unrestrictedAdministration && (
            <Descriptions.Item label="Project Id">{`${project.idProject}`}</Descriptions.Item>
          )}
        </Descriptions>
      )}
    </ShiftedDrawer>
  );
};
