import type { ReactElement } from 'react';

import { LatestTag } from '../../../../../../shared/components/LatestTag';
import { CommonSoftwareAppVersionVulnerabilityButton } from '../../../SoftwareItemVersionVulnerabilityButton/CommonSoftwareAppVersionVulnerabilityButton';
import { ProjectSoftwareAppVersionVulnerabilityButton } from '../../../SoftwareItemVersionVulnerabilityButton/ProjectSoftwareAppVersionVulnerabilityButton';

import type { Project, SoftwareApp, SoftwareAppVersion } from '../../../../../../../api/engineering/domain/types';
import {BundleTag} from "../../../../../../shared/components/BundleTag";

/**
 * Used inside the version name column of version list tables.
 * It displays the name, along with a latest tag if it is the
 * the latest version and vulnerability information.
 */
export function SoftwareAppVersionTitle(props: { project?: Project; app: SoftwareApp; version: SoftwareAppVersion, bundleVersion?: SoftwareAppVersion }) {
  const { project, app, version, bundleVersion } = props;

  let vulnerabilityButton: ReactElement | null = null;

  if (!!project) {
    vulnerabilityButton = <ProjectSoftwareAppVersionVulnerabilityButton project={project} app={app} version={version} />;
  } else {
    vulnerabilityButton = <CommonSoftwareAppVersionVulnerabilityButton app={app} version={version} />;
  }

  return (
    <>
      {version.idSoftwareAppVersion === app.latestVersion?.idSoftwareAppVersion && <LatestTag />}
      {version.idSoftwareAppVersion === bundleVersion?.idSoftwareAppVersion && <BundleTag />}
      {version.version}
      {vulnerabilityButton}
    </>
  );
}
