import { useQuery } from '@tanstack/react-query';
import { BundleReleaseLite } from '../../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../../api/engineering/hooks/useEngineeringBackend';
import { SemanticVersion } from '../../../../domain';
import { usePermissions } from '../../../session/hooks/usePermissions';

export function useConfigurationVersions(projectId: string, bundleId: string, configurationId: string | undefined = '0') {
  const { backend } = useEngineeringBackend();
  const parsedProjectId = parseInt(projectId, 10);
  const parsedBundleId = parseInt(bundleId, 10);
  const parsedConfigId = parseInt(configurationId, 10);
  const permissions = usePermissions({ projectId });
  return useQuery<BundleReleaseLite[], [string, string, string, string]>(
    ['bundleConfigurationVersions', projectId, bundleId, configurationId],
    (): Promise<BundleReleaseLite[]> => {
      return new Promise((resolve, reject) => {
        backend
          .queryBundleConfigurationVersions(projectId, bundleId, configurationId)
          .then((releases) => {
            resolve(releases.sort((a, b) => SemanticVersion.sorter(a.version!, b.version!)));
          })
          .catch(reject);
      });
    },
    {
      enabled:
        parsedBundleId > 0 &&
        parsedConfigId > 0 &&
        parsedProjectId > 0 &&
        Boolean(configurationId) &&
        permissions.engineeringSvc$getProjectBundleConfigurationReleases
    }
  );
}

export default useConfigurationVersions;
