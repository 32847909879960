import { ImportOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Space, Typography } from 'antd';
import { useRef, useState } from 'react';
import { useOtcs } from '../hooks/useOtcs';
import { CreateDeviceListEntry } from './CreateDeviceListEntry';
import { ScrollDialog } from '../../layout/components/ScrollDialog';
import { DescriptionTextAreaInput } from '../../shared/components/DescriptionTextAreaInput';
import { Otc } from '../../../api';
import { uniq } from 'lodash';
import { useParams } from 'react-router-dom';

export const DeviceCsvPopover = (props: { projectId: number }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const params = useParams();
  const envId = params?.envId || '';
  const otcs = useOtcs(props.projectId, envId);
  const [input, setInput] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const listData = useRef<React.ReactElement[]>([]);
  const close = () => {
    setPopoverVisible(false);
    setInput('');
  };
  return (
    <>
      <Popover
        content={
          <>
            <DescriptionTextAreaInput value={input} onChange={(ev) => setInput(ev.target.value)} />
            <Space style={{ width: '100%' }}>
              <Button
                block
                type="link"
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
              <Button
                type="link"
                block
                onClick={() => {
                  const lines = input
                    .split('\n')
                    .map((v) => v.trim())
                    .filter((v) => v);
                  const notFoundOtcs: string[] = [];
                  const deviceForms = lines.map((line, index) => {
                    const splitted = line.split(',');
                    if (splitted.length < 2) {
                      throw new Error(`Invalid device ${line}`);
                    }
                    if (splitted.length === 2) {
                      splitted.push('');
                    }
                    const otcIds = splitted[0].split(';');
                    if (otcIds.length < 1) {
                      throw new Error(`Invalid otcs ${line}`);
                    }

                    const otcsObjects = otcIds
                      .map((o) => o.trim())
                      .map((otc) => {
                        const foundOtc = otcs.data?.find((o) => o.name === otc);
                        if (!foundOtc) {
                          notFoundOtcs.push(otc);
                        }
                        return foundOtc;
                      })
                      .filter((o) => o) as Otc[];
                    return (
                      <>
                        <Divider>
                          Device
                          {index + 1}
                        </Divider>
                        <CreateDeviceListEntry
                          key={line}
                          projectId={props.projectId}
                          initial={{
                            actionLog: [],
                            description: splitted[2].trim(),
                            id: '',
                            mvccId: 0,
                            otc: otcsObjects,
                            rds: splitted[1].trim(),
                            createdAt: '',
                            createdBy: 0,
                            createdByName: '',
                            updatedAt: '',
                            updatedBy: 0,
                            updatedByName: ''
                          }}
                        />
                      </>
                    );
                  });
                  const uniqueNotFoundOtcs = uniq(notFoundOtcs);
                  listData.current =
                    uniqueNotFoundOtcs.length > 0
                      ? uniqueNotFoundOtcs.map((otc) => (
                          <p>
                            MISSING OTC:
                            {otc}
                          </p>
                        ))
                      : deviceForms;
                  setModalVisible(true);
                  close();
                }}
              >
                OK
              </Button>
            </Space>
          </>
        }
        title={
          <Space direction="vertical">
            <span>Import Devices from CSV</span>
            <Typography.Text type='warning'>We recommend copying Devices and OTCs from the same Environment.</Typography.Text>
            <span>Example: <Typography.Text code>XF320; XF321; XF330,#UL74-YC01-XF10-UH01,Communication</Typography.Text></span>
          </Space>
        }
        trigger="click"
        open={popoverVisible}
      >
        <Button disabled={!otcs.isSuccess} onClick={() => setPopoverVisible(true)} icon={<ImportOutlined />}>
          Import CSV
        </Button>
      </Popover>
      <ScrollDialog
        title="Create Devices"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        footer={[<Button onClick={() => setModalVisible(false)}>Close</Button>]}
      >
        {listData.current}
      </ScrollDialog>
    </>
  );
};

export default DeviceCsvPopover;
